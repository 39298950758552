import { withRouter } from 'hoc/withRouter';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Row,
  Button,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import PhotoGallery from 'views/components/PhotoGallery';

import AnosLetivosService from 'services/AnosLetivosService';
import AwsService from 'services/AwsService';
import EntesService from 'services/EntesService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';

import Turno from 'assets/csv/turnos.json';

class MuralFotosAluno extends React.Component {
  constructor(props) {
    super(props);

    this.fileInputRef = React.createRef();

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.awsService = new AwsService();

    this.state = {
      showAlert: false,
      showLoaderModal: false,
      error: null,

      nome: '',
      dataStr: '',
      viewDate: new Date(),
      imagens: [],
    };

  }

  async componentDidMount() {
    try {

      const matricula = await this.turmasService
        .carregarMatriculaPorId(this.props.match.params.idMatricula);

      const turma = await this.turmasService
        .carregarTurma(matricula.idTurma);

      const muralFotos = await this.turmasService.carregarMuralFotos(this.props.match.params.idMuralFotos);

      const fotos = await this.turmasService.carregarMuralFotosFotos(this.props.match.params.idMuralFotos);

      const urlPromises = fotos.map((foto) => new Promise(async (resolve) => {
        const url = await this.awsService.getPresignedUrl(foto.url);
        resolve(url);
      }));

      const urls = await Promise.all(urlPromises);

      const imagens = fotos.map((foto, idx) => ({
        ...foto,
        src: urls[idx],
      }));

      this.setState({
        matricula,
        turma,
        nome: muralFotos.nome,
        data: muralFotos.data,
        dataStr: muralFotos.data,
        imagens,
      });

    } catch (e) {
      console.log(e)
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return this.state.matricula ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/turmas`}>Turmas</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/turmas/${this.props.match.params.idMatricula}`}>{this.state.turma.curso.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Mural de Fotos</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Mural de Fotos
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="5">
                  <FormGroup>
                    <label>Nome</label>
                    <Input
                      disabled={true}
                      value={this.state.nome}
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <label>Data</label>
                    <Input
                      disabled
                      value={this.state.dataStr}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={`has-label ${this.state.imagensState}`}>
                    <PhotoGallery
                      images={this.state.imagens}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history.push(`${this.props.layout}/turmas/${this.props.match.params.idMatricula}`)} >
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text="Salvando registro de diário do aluno..." />
      </div>
    );
  }
}

export default withRouter(MuralFotosAluno);
