import { withRouter } from 'hoc/withRouter';
import React from 'react';

import { Link } from 'react-router-dom';

import moment from 'moment';
import isDateFormat from 'is-date-format';

import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
  FormGroup,
  Input,
  Button,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import PhotoGallery from 'views/components/PhotoGallery';
import DatePicker from 'views/components/DatePicker';
import ConfirmModal from 'views/components/ConfirmModal';

import EntesService from 'services/EntesService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import AwsService from 'services/AwsService';

import Turno from 'assets/csv/turnos.json';

class CadastroMuralFotos extends React.Component {
  constructor(props) {
    super(props);

    this.fileInputRef = React.createRef();

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.awsService = new AwsService();

    this.state = {
      showAlert: false,
      showLoaderModal: false,
      error: null,

      nome: '',
      dataStr: '',
      viewDate: new Date(),
      imagensSelecionadas: [],
    };

    this.cadastrarMuralFotos = this.cadastrarMuralFotos.bind(this);
    this.validarCampos = this.validarCampos.bind(this);



  }

  async componentDidMount() {
    try {
      const dadosEnte = await this.entesService
        .carregarDadosEnte();

      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      if (this.props.match.params.idMuralFotos) {
        this.carregarMuralFotos();
      }

      this.setState({
        dadosEnte,
        anoLetivo,
        escola,
        turma,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarMuralFotos() {
    const muralFotos = await this.turmasService.carregarMuralFotos(this.props.match.params.idMuralFotos);

    const fotos = await this.turmasService.carregarMuralFotosFotos(this.props.match.params.idMuralFotos);

    const urlPromises = fotos.map((foto) => new Promise(async (resolve) => {
      const url = await this.awsService.getPresignedUrl(foto.url);
      resolve(url);
    }));

    const urls = await Promise.all(urlPromises);

    const imagensSelecionadas = fotos.map((foto, idx) => ({
      ...foto,
      src: urls[idx],
    }));

    this.setState({
      muralFotosSelecionado: muralFotos,
      nome: muralFotos.nome,
      data: muralFotos.data,
      dataStr: muralFotos.data,
      imagensSelecionadas,
    });
  }

  validarCampos() {
    let ret = true;
    if (!this.state.nome) {
      this.setState({ nomeState: 'has-danger' });
      ret = false;
    }
    if (this.state.dataStr === '') {
      this.setState({
        dataState: 'has-danger',
        dataErro: 'Selecione uma data',
      });
      ret = false;
    } else if (!isDateFormat(this.state.dataStr, 'dd/mm/yyyy')) {
      this.setState({
        dataState: 'has-danger',
        dataErro: 'Data inválida',
      });
      ret = false;
    }
    if (this.state.imagensSelecionadas.length === 0) {
      this.setState({ imagensState: 'has-danger' });
      ret = false;
    }
    return ret;
  }

  async cadastrarMuralFotos() {
    if (!this.validarCampos()) return;
    if (this.props.match.params.idMuralFotos) return this.atualizarMuralFotos();

    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Cadastrando mural de fotos...',
      showAlert: false,
    });

    try {
      const fotos = this.state.imagensSelecionadas.map((imagem) => ({
        conteudo: imagem.src.replace(/^data:.*;base64,/, ''),
        width: imagem.width,
        height: imagem.height,
      }));

      const muralFotos = {
        nome: this.state.nome,
        data: this.state.dataStr,
        fotos: [fotos[0]],
        turma: { id: this.props.match.params.idTurma }
      };

      const idMural = await this.turmasService.cadastrarMuralFotos(this.props.match.params.idTurma, muralFotos);

      if (fotos.length > 1) {
        const calda = fotos.slice(1, fotos.length);

        for (const foto of calda) {
          await this.turmasService.adicionarFotoMural(idMural, foto);
        }
      }

      this.limparCampos();

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Mural de fotos cadastrado com sucesso.',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao cadastrar mural de fotos',
      });
    }
  }

  async atualizarMuralFotos() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Atualizando mural de fotos...',
      showAlert: false,
    });

    try {
      const muralFotos = {
        ...this.state.muralFotosSelecionado,
        nome: this.state.nome,
        data: this.state.dataStr,
      };

      await this.turmasService.atualizarMuralFotos(this.props.match.params.idTurma, muralFotos);

      const fotos = this.state.imagensSelecionadas.filter((imagem) => !imagem.id);

      if (fotos.length > 0) {
        fotos.forEach((foto) => Object.assign(foto, {
          conteudo: foto.src.replace(/^data:.*;base64,/, ''),
          width: foto.width,
          height: foto.height,
        }))

        for (const foto of fotos) {
          await this.turmasService.adicionarFotoMural(this.state.muralFotosSelecionado.id, foto);
        }
      }

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Mural de fotos atualizado com sucesso.',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao atualizar mural de fotos',
      });
    }
  }

  async removerFotos() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Removendo fotos...',
      showAlert: false,
    });

    try {
      const fotos = this.state.imagensRemocao.filter((foto) => foto.id).map((foto) => foto.id);
      const tagsRemocao = this.state.imagensRemocao.filter((foto) => foto.tag).map((foto) => foto.tag);

      this.setState({
        imagensSelecionadas: this.state.imagensSelecionadas.filter((imagem) => !tagsRemocao.includes(imagem.tag)),
        showLoaderModal: true,
      });
      
      if (this.props.match.params.idMuralFotos && fotos.length > 0) {
        await this.turmasService.removerFotos(this.props.match.params.idMuralFotos, fotos);
        await this.carregarMuralFotos();
        
        this.setState({
          showAlert: true,
          alertColor: 'success',
          alertMsg: 'Fotos removidas com sucesso.',
        });
      }

      this.setState({
        showLoaderModal: false,
      })
      
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover fotos',
      });
    }
  }


  limparCampos() {
    this.setState({
      nome: '',
      data: '',
      dataStr: '',
      imagensSelecionadas: [],

      nomeState: '',
      dataState: '',
      imagensState: '',
    });
  }

  voltar() {
    if (!this.state.turma.multiSeriado) {
      return `${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`;
    }
    return `${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}`
      + `/turma/multiseriado/${this.state.turmaMultiseriado.id}`;
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            {
              !this.state.turma.multiSeriado
                ? <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
                : <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            }
            <BreadcrumbItem active>Cadastro de Mural de Fotos</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Cadastro de Mural de Fotos
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="5">
                  <FormGroup className={`has-label ${this.state.nomeState}`}>
                    <label>Nome *</label>
                    <Input
                      value={this.state.nome}
                      type="text"
                      maxLength="30"
                      onChange={(e) => this.setState({
                        nome: e.target.value,
                        nomeState: 'has-success'
                      })}
                    />
                    {this.state.nomeState === 'has-danger' ? (
                      <label className="error">
                        Informe o nome do mural de fotos.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup className={`has-label ${this.state.dataState}`}>
                    <label>Data *</label>
                    <DatePicker
                      viewDate={this.state.viewDate}
                      value={this.state.data}
                      onChange={(data) => {
                        const dataStr = moment(data).format('DD/MM/YYYY');
                        this.setState({
                          viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                          data: dataStr,
                          dataStr,
                          dataState: '',
                        });
                      }}
                    />
                    {this.state.dataState === 'has-danger' ? (
                      <label className="error">
                        {this.state.dataErro}
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={`has-label ${this.state.imagensState}`}>
                    <PhotoGallery
                      selectMode
                      images={this.state.imagensSelecionadas}
                      onSelect={(images) => this.setState({ imagensSelecionadas: images, imagensState: '' })}
                      onRemove={(images) => this.setState({ imagensRemocao: images, showConfirmModal: true })}
                    />
                    {this.state.imagensState === 'has-danger' ? (
                      <label className="error">
                        Adicione ao menos uma imagem no mural
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history.push(this.voltar())} >
                    Voltar
                  </Button>
                </Col>
                <Col className="text-right">
                  <Button
                    color="primary"
                    disabled={this.state.showLoaderModal}
                    onClick={this.cadastrarMuralFotos} >
                    {
                      !this.props.match.params.idMuralFotos ? 'Cadastrar mural de fotos' : 'Atualizar mural de fotos'
                    }
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerFotos();
            } else {
              this.setState({ imagensRemocao: null });
            }
          }}
          text='Confirme a exclusão das fotos' />
      </div>
    );
  }
}

export default withRouter(CadastroMuralFotos);
