import React from 'react';
import { withRouter } from 'hoc/withRouter';

// reactstrap components
import {
	Card,
	CardHeader,
	CardTitle,
	CardBody,
	Row,
	Col,
	Breadcrumb,
	BreadcrumbItem,
	Table,
	FormGroup,
	Button,
} from 'reactstrap';

import Select from 'react-select';

import Loader from 'react-loader-spinner';

import EntesService from 'services/EntesService';

import Papel from 'assets/csv/papeis.json';

class RelatoriosEstatisticos extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			erro: undefined,
		};

		this.entesService = new EntesService();
	}

	imprimirHistoricoAluno() {
		this.props.history.push(`${this.props.layout}`
			+ `/escolas/${this.props.match.params.idEscola}`
			+ '/historicos');
	}

	async componentDidMount() {
		try {
			const dadosEnte = await this.entesService
				.carregarDadosEnte();

			const tempRelatorios = [
				//Raiz
				{ label: 'Relação de datas do ano letivo', value: '/relatorios/datasanoletivo' },
				this.props.role === Papel.GESTOR.value ? { label: 'Relatório de matrículas não renovadas', value: '/relatorios/matriculasnaorenovadas' } : null,

				//Relatórios de escolas e turmas
				{ label: 'Relatório de turmas por escola', value: '/relatorios/escolasturmas/turmasporescola' },
				{ label: 'Relatório de quantidade de turmas por série', value: '/relatorios/escolasturmas/turmasporserie' },
				{ label: 'Relatório de diários por escola', value: '/relatorios/escolasturmas/diariosporescola' },
				{ label: 'Relação de funcionários por escola', value: '/relatorios/escolasturmas/funcionariosporescola' },
				{ label: 'Relatório de ocorrências por escola', value: '/relatorios/escolasturmas/ocorrenciasporescola' },
				{ label: 'Relação de justificativas de faltas por turma', value: '/relatorios/escolasturmas/justificativasfaltasporturma' },
				{ label: 'Relatório livro de matrículas por escola', value: '/relatorios/escolasturmas/livromatriculaporescola' },
				{ label: 'Relatório livro de matrículas por turma', value: '/relatorios/escolasturmas/livromatriculaporturma' },
				{ label: 'Relatório de notas finais por turma', value: '/relatorios/escolasturmas/notasfinaisporturma' },
				{ label: 'Relatório de frequências mensais por turma', value: '/relatorios/escolasturmas/frequenciasmensaisporturma' },
				{ label: 'Relatório de quantidade de frequência lançada por dia', value: '/relatorios/escolasturmas/quantidadefrequenciadia' },
				{ label: 'Listagem de relatórios de turmas não informados por escola', value: '/relatorios/escolasturmas/listagemrelatoriosturmasnaoinformadosporescola' },
				{ label: 'Relatório de fardamento de alunos por escola', value: '/relatorios/escolasturmas/fardamentoalunosporescola' },
				{ label: 'Relatório de total de fardamento por escola', value: '/relatorios/escolasturmas/totalfardamentoporescola' },

				//Relatórios de distorção idade série
				{ label: 'Relatório de distorção idade série por escola', value: '/relatorios/distorcaoidadeserie/porescola' },
				this.props.role === Papel.GESTOR.value ? { label: 'Relatório de distorção idade série no município', value: '/relatorios/distorcaoidadeserie/pormunicipio' } : null,

				//Relatórios de professores
				{ label: 'Relatório de professores por escola', value: '/relatorios/professores/porescola' },
				{ label: 'Relatório de professores por turma', value: '/relatorios/professores/porturma' },
				this.props.role === Papel.GESTOR.value ? { label: 'Relatório de professores cadastrados', value: '/relatorios/professores/cadastrados' } : null,

				//Relatórios de alunos
				{ label: 'Relatório de alunos por escola', value: '/relatorios/alunos/porescola' },
				{ label: 'Relatório de alunos por turma', value: '/relatorios/alunos/porturma' },
				{ label: 'Relatório de alunos com necessidades educacionais específicas por escola', value: '/relatorios/alunos/pneporescola' },
				{ label: 'Relatório de alunos com necessidades educacionais específicas por turma', value: '/relatorios/alunos/pneporturma' },
				{ label: 'Relatório de alunos por idade', value: '/relatorios/alunos/poridade' },
				{ label: 'Relatório de alunos fora de faixa por escola', value: '/relatorios/alunos/foradefaixaporescola' },
				{ label: 'Relatório de alunos abaixo da média por turma', value: '/relatorios/alunos/abaixodamediaporturma' },
				{ label: 'Relatório de objetivos de aprendizagem/habilidades adquiridas por aluno', value: '/relatorios/alunos/habilidadesadquiridasporaluno' },
				{ label: 'Relatório de objetivos de aprendizagem/habilidades adquiridas por turma', value: '/relatorios/alunos/habilidadesadquiridasporturma' },
				{ label: 'Relatório de notas de alunos por turma', value: '/relatorios/alunos/notasporturma' },
				{ label: 'Relatório de médias de alunos por escola', value: '/relatorios/alunos/mediasporescola' },
				{ label: 'Relatório de notas de alunos por componente curricular', value: '/relatorios/alunos/notaspormateria' },
				{ label: 'Relatório de notas de alunos por professor', value: '/relatorios/alunos/notasporprofessor' },
				{ label: 'Relatório de notas de alunos por bimestre', value: '/relatorios/alunos/notasporbimestre' },
				{ label: 'Relatório de frequência de alunos por mês', value: '/relatorios/alunos/frequenciapormes' },
				{ label: 'Relatório de frequência de alunos por bimestre', value: '/relatorios/alunos/frequenciaporbimestre' },
				{ label: 'Relatório de frequência de alunos por componente curricular/objetivo de aprendizagem', value: '/relatorios/alunos/frequenciapormateria' },
				{ label: 'Relatório de contagem de alunos por escola', value: '/relatorios/alunos/contagemporescola' },
				{ label: 'Relatório de contagem de alunos por escola e etapa de ensino', value: '/relatorios/alunos/contagemporescolaetapa' },
				{ label: 'Relatório de contagem de alunos aprovados/reprovados por escola', value: '/relatorios/alunos/percentualaprovadoreprovadoporescola' },
				{ label: 'Relatório de faltas do aluno', value: '/relatorios/alunos/faltas' },
				this.props.role === Papel.GESTOR.value ? { label: 'Relatórios de alunos sem matrículas e sem históricos', value: 'alunosSemMatriculas' } : null,
				dadosEnte.avaliacaoEspecial ? { label: 'Relatório de plano educacional individualizado', value: '/relatorios/alunos/pei' } : null,
				{ label: 'Relatório de alunos por turno por escola', value: '/relatorios/alunos/turno' },
				{ label: 'Relatório de alunos matriculados em dependência', value: '/relatorios/alunos/matriculados/dependencia' },
				{ label: 'Relatório de alunos aprovados em dependência', value: '/relatorios/alunos/aprovados/dependencia' },
				{ label: 'Relatório de alunos do bolsa família por escola', value: '/relatorios/alunosbolsafamilia/porescola' },
				{ label: 'Relatório de alunos por zona urbana e rural', value: '/relatorios/alunos/porzonaurbanarural' },
				{ label: 'Relatório de alunos com alergias', value: '/relatorios/alunos/comalergias' },

				//Relatórios de transportes
				this.props.role === Papel.GESTOR.value ? { label: 'Relação de passageiros por transporte', value: '/relatorios/transportes/passageirosportransporte' } : null,
				{ label: 'Relação de passageiros por turma', value: '/relatorios/transportes/passageirosporturma' },

				//Relatórios de registros de aula
				dadosEnte.registroAulas ? { label: 'Relatório de registros de aula por escola', value: '/relatorios/registrosaula/porescola' } : null,
				dadosEnte.registroAulas ? { label: 'Relatório de registros de aula por turma', value: '/relatorios/registrosaula/porturma' } : null,
				dadosEnte.registroAulas ? { label: 'Relatório de registros de aula por professor', value: '/relatorios/registrosaula/porprofessor' } : null,
				dadosEnte.registroAulas ? { label: 'Relatório de quantidade de registros de aula por escola', value: '/relatorios/quantidaderegistrosaula/porescola' } : null,
				dadosEnte.registroAulas ? { label: 'Relatório de quantidade de registros de aula por turma', value: '/relatorios/quantidaderegistrosaula/porturma' } : null,
				dadosEnte.registroAulas ? { label: 'Relatório de quantidade de registros de aula por professor', value: '/relatorios/quantidaderegistrosaula/porprofessor' } : null,
				dadosEnte.registroAulas ? { label: 'Relatório de diários com registros de aula em atraso', value: '/relatorios/diariosregistroaulasematraso/porescola' } : null,

				dadosEnte.controleEstoque ? { label: 'Relatório de entradas de estoque por escola', value: '/relatorios/estoques/entradasporescola' } : null,
				dadosEnte.controleEstoque ? { label: 'Relatório de saídas de estoque por escola', value: '/relatorios/estoques/saidasporescola' } : null,

				//Relatórios de eventos
				this.props.role === Papel.GESTOR.value ? { label: 'Relatório de presenças confirmadas em eventos da SME', value: '/relatorios/eventos/sme/presencasconfirmadas' } : null,
				{ label: 'Relatório de presenças confirmadas em eventos da escola', value: '/relatorios/eventos/escola/presencasconfirmadas' }
			];

			const relatorios = tempRelatorios.filter((r) => r !== null);

			this.setState({
				dadosEnte,
				relatorios,
			})
		} catch (e) {
			this.setState({ erro: true });
		}
	}

	validarCampos() {
		let ret = true;

		if (!this.state.relatorioSelecionado) {
			this.setState({ relatorioSelecionadoState: 'danger' });
			ret = false;
		}

		return ret;
	}

	abrirRelatório() {
		if (!this.validarCampos()) return;
		this.props.history.push(`${this.props.layout}${this.state.relatorioSelecionado.value}`)
	}

	conteudoPagina() {
		return this.state.dadosEnte ? <div className="content">
			<Row>
				<Col md="12">
					<Breadcrumb>
						<BreadcrumbItem active>Relatórios</BreadcrumbItem>
					</Breadcrumb>
				</Col>
			</Row>
			<Row>
				<Col>
					<Card>
						<CardHeader>
							<CardTitle></CardTitle>
						</CardHeader>
						<CardBody>
							<Row>
								<Col md="5">
									<FormGroup className={'has-label has-danger'}>
										<label>Relatórios</label>
										<Select
											noOptionsMessage={() => 'Nenhuma entrada'}
											className={`react-select primary ${this.state.relatorioSelecionadoState}`}
											classNamePrefix="react-select"
											value={this.state.relatorioSelecionado}
											onChange={(event) => {
												this.setState({ relatorioSelecionadoState: '', relatorioSelecionado: event });
											}}
											options={this.state.relatorios}
											placeholder="Relatórios..."
										/>
										{this.state.relatorioSelecionadoState === 'danger' ? (
											<label className="error">
												Selecione um relatório.
											</label>
										) : null}
									</FormGroup>
								</Col>
								<Col md="1" className="mt-3">
									<Button color="primary" onClick={() => this.abrirRelatório()}>
										Abrir
									</Button>
								</Col>
							</Row>
							<Table hover>
								<thead className="text-primary">
									<tr>
										<th>Relatórios</th>
									</tr>
								</thead>
								<tbody>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/datasanoletivo`)}>
										<td>Relação de datas do ano letivo</td>
									</tr>
									{
										this.props.role === Papel.GESTOR.value && <tr
											className='tr-clickable'
											onClick={() => this.props.history.push(`${this.props.layout}/relatorios/matriculasnaorenovadas`)}>
											<td>Relatório de matrículas não renovadas</td>
										</tr>
									}
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/escolasturmas`)}>
										<td>Relatórios de escolas e turmas <i className="fas fa-angle-double-right"></i></td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/distorcaoidadeserie`)}>
										<td>Relatórios de distorção idade série <i className="fas fa-angle-double-right"></i></td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/professores`)}>
										<td>Relatórios de professores <i className="fas fa-angle-double-right"></i></td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos`)}>
										<td>Relatórios de alunos <i className="fas fa-angle-double-right"></i></td>
									</tr>
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/transportes`)}>
										<td>Relatórios de transportes <i className="fas fa-angle-double-right"></i></td>
									</tr>
									{
										this.state.dadosEnte.registroAulas && <tr
											className='tr-clickable'
											onClick={() => this.props.history.push(`${this.props.layout}/relatorios/registrosaula`)}>
											<td>Relatórios de registros de aula <i className="fas fa-angle-double-right"></i></td>
										</tr>
									}
									{
										this.state.dadosEnte.controleEstoque && <tr
											className='tr-clickable'
											onClick={() => this.props.history.push(`${this.props.layout}/relatorios/estoques`)}>
											<td>Relatórios de estoques <i className="fas fa-angle-double-right"></i></td>
										</tr>
									}
									<tr
										className='tr-clickable'
										onClick={() => this.props.history.push(`${this.props.layout}/relatorios/eventos`)}>
										<td>Relatórios de eventos <i className="fas fa-angle-double-right"></i></td>
									</tr>
								</tbody>
							</Table>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</div> : <Card>
			<div align="center" style={{ margin: 50 }}>
				<Loader
					type="Oval"
					color="#34B5B8"
					height="50"
					width="50" />
			</div>
		</Card>
	}

	render() {
		return (
			<div className='content'>
				{!this.state.erro
					? this.conteudoPagina()
					: <Card>
						<div align="center" style={{ margin: 50 }}>
							Erro ao buscar informações da página
						</div>
					</Card>
				}
			</div>
		);
	}
}

export default withRouter(RelatoriosEstatisticos);
