import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  FormGroup,
  Input,
} from 'reactstrap';

import moment from 'moment';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import NumberFormat from 'react-number-format';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import MetodologiaModal from 'views/components/MetodologiaModal';

import EntesService from 'services/EntesService';
import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import FeriadosService from 'services/FeriadosService';
import DiasCanceladosService from 'services/DiasCanceladosService';
import DiasExtrasService from 'services/DiasExtrasService';
import RegistroAulasService from 'services/RegistroAulasService';
import ObjetivosAprendizagemCampoExperienciaService from 'services/ObjetivosAprendizagemCampoExperienciaService';
import HabilidadesCompetenciasEspecificasService from 'services/HabilidadesCompetenciasEspecificasService';

import Turno from 'assets/csv/turnos.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';

class RegistroAulaMultiseriadoAgrupado extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.state = {
      showAlert: false,
      showLoaderModal: false,
      showMetodologiaModal: false,

      objetoConhecimento: '',
      metodologia: '',
      avaliacao: '',
      observacoes: '',
      direitosAprendizagem: '',
      numeroAulas: '',

      materiasSelecionadas: [],
      materias: [],

      objetivosAprendizagem: [],
      objetivosAprendizagemSelecionados: [],
      habilidades: [],
      habilidadesSelecionadas: [],

      error: null,
    };

    this.entesService = new EntesService();
    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.feriadosService = new FeriadosService();
    this.diasCanceladosService = new DiasCanceladosService();
    this.diasExtrasService = new DiasExtrasService();
    this.registroAulasService = new RegistroAulasService();
    this.objetivosAprendizagemCampoExperienciaService = new ObjetivosAprendizagemCampoExperienciaService();
    this.habilidadesCompetenciasEspecificasService = new HabilidadesCompetenciasEspecificasService();

  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();

      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      let turmas = [];
      let diarios = [];
      if (!turma.multiSeriado) {
        turmas.push(turma);
        diarios.push(diario);
      } else {
        const _turmas = await this.turmasService.carregarTurmasDaTurmaMultiseriado(turma.turmaMultiseriado.id);
        const _diarios = await this.diariosService.carregarDiariosDaTurmaMultiseriado(turma.turmaMultiseriado.id);

        turmas = _turmas.filter((t) => t.curso.cicloEnsino === turma.curso.cicloEnsino);
        diarios = _diarios.filter((d) => d.cicloEnsino === turma.curso.cicloEnsino);
      }

      const materia = turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
        ? 'Campo de experiências' : 'Componente curricular';

      const objetivo = turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value && anoLetivo.ente.nome !== 'Patos'
        ? 'Objetivos de aprendizagem e desenvolvimento' : 'Objetos de conhecimento';

      const diasUteis = await this.anosLetivosService
        .carregarDiasUteisAnoLetivo(this.props.match.params.idAnoLetivo);

      const dias = await this.diariosService
        .carregarDiasDeAulaDoProfessor(this.props.match.params.idDiario);

      this.setState({
        dadosEnte,
        anoLetivo,
        escola,
        turma,
        turmas,
        diario,
        diarios,
        alfabetizacao: turma.tipoAvaliacao === TipoAvaliacao.RELATORIO.value ? '/alfabetizacao' : '',
        materia,
        objetivo,
        diasUteis,
        dias,
      }, async () => {
        const selectDatas = [];

        let inicio = moment(anoLetivo.dataInicioBimestre1, 'DD-MM-YYYY');
        const fim = moment(anoLetivo.dataFimBimestre4, 'DD-MM-YYYY');

        while (!inicio.isAfter(fim)) {
          if (this.diaUtil(inicio.toDate())) {
            selectDatas.push({
              value: inicio.format("DD/MM/YYYY"),
              label: inicio.format("DD/MM/YYYY - ddd").toUpperCase(),
            });
          }
          inicio.add(1, 'days');
        }

        this.setState({
          selectDatas,
        });

        if (this.props.match.params.idRegistroAula !== undefined) {
          const registroAula = await this.registroAulasService
            .carregarRegistroAula(this.props.match.params.idRegistroAula);

          const materias = await this.registroAulasService.carregarMateriasRegistroAula(registroAula.id);
          const materiasSelecionadas = materias.map((materiaRegistroAula) => materiaRegistroAula.materia);
          materiasSelecionadas.forEach((materia) => {
            Object.assign(materia, { value: materia.id, label: materia.nome, nome: materia.nome });
          });

          await this.carregarMateriasDoProfessorNoDia({ label: registroAula.data });

          await this.carregarObjetivosAprendizagemHabilidades(materiasSelecionadas);

          for (let diario of this.state.diarios) {
            const raoas = await this.registroAulasService
              .carregarObjetivosAprendizagemPorAgrupador(diario.id, registroAula.idAgrupador);

            const rahs = await this.registroAulasService
              .carregarHabilidadesPorAgrupador(diario.id, registroAula.idAgrupador);

            Object.assign(diario, {
              objetivosAprendizagemSelecionados: raoas.map((raoa) => ({ value: raoa.objetivoAprendizagem.id, label: raoa.objetivoAprendizagem.descricao })),
              habilidadesSelecionadas: rahs.map((rah) => ({ value: rah.habilidade.id, label: rah.habilidade.descricao })),
            });
          }

          this.setState({
            registroAulaSelecionado: registroAula,
            materiasSelecionadas,
          }, () => this.contentLoad());

        }
      });

    } catch (e) {
      this.setState({ erro: true });
    }
  }

  contentLoad() {
    this.setState({
      dataSelecionada: {
        value: this.state.registroAulaSelecionado.data,
        label: this.state.registroAulaSelecionado.data,
      },
      dataFimSelecionada: {
        value: this.state.registroAulaSelecionado.dataFim,
        label: this.state.registroAulaSelecionado.dataFim,
      },
      numeroAulas: this.state.registroAulaSelecionado.numeroAulas,
      objetoConhecimento: this.state.registroAulaSelecionado.objetoConhecimento,
      metodologia: this.state.registroAulaSelecionado.metodologia,
      avaliacao: this.state.registroAulaSelecionado.avaliacao,
      observacoes: this.state.registroAulaSelecionado.observacoes,
      direitosAprendizagem: this.state.registroAulaSelecionado.direitosAprendizagem,
    });
  }

  diaUtil(date) {
    const dateStr = moment(date).format('DD-MM-YYYY');
    const diaAula = !this.state.turma.frequenciaRestrita || this.state.dias.some((d) => d === (date.getDay() - 1));
    const diaUtil = this.state.diasUteis.some((d) => d === dateStr);
    return diaAula && diaUtil;
  }

  async carregarMateriasDoProfessorNoDia(data) {
    this.setState({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Carregando matérias do professor na data selecionada...',
    });

    try {

      const mData = moment(data.label, 'DD/MM/YYYY');
      const dia = mData.toDate().getDay() - 1;
      const fimDeSemana = mData.day() === 6 || mData.day() === 0;

      let materias;
      const selectMaterias = [];

      if (!fimDeSemana && !(this.state.dadosEnte.registroAulasPeriodo && this.state.turma.curso.cicloEnsino !== CicloEnsino.ENSINO_FUNDAMENTAL_2.value)) {
        materias = await this.diariosService
          .carregarMateriasDoProfessorNoDia(this.props.match.params.idDiario, dia);

        const fn = (materia) => new Promise((resolve) => resolve(
          this.diariosService.carregarQuantidadeDeAulasDoProfessorMateria(
            this.props.match.params.idDiario, materia.id, dia,
          ),
        ));

        const actions = materias.map(fn);
        const quantidadesAulas = await Promise.all(actions);

        materias.forEach((materia, idx) => {
          selectMaterias.push({
            id: materia.id,
            value: materia.id,
            label: materia.nome,
            nome: materia.nome,
            numeroAulas: quantidadesAulas[idx],
          });
        });

      } else {
        materias = await this.diariosService
          .carregarMateriasDoProfessor(this.props.match.params.idDiario);

        materias.forEach((materia) => {
          selectMaterias.push({
            id: materia.id,
            value: materia.id,
            label: materia.nome,
          });
        });
      }

      this.setState({
        showLoaderModal: false,
        selectMaterias,
      });
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao carregar matérias do professor na data selecionada',
      });
    }
  }

  voltar() {
    if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
      return `${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`
        + `/diario/${this.state.diario.id}`;
    }
    return `${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}`
      + `/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao`;
  }

  handleClick = () => {
    if (!this.lastClickTime) {
      this.lastClickTime = new Date();
      this.countClick = 1;
    } else {
      let newClickTime = new Date();
      if (newClickTime - this.lastClickTime < 300) {
        this.lastClickTime = newClickTime;
        this.countClick += 1;
        if (this.countClick === 4) {
          this.setState({ showMetodologiaModal: true });
          this.lastClickTime = undefined;
          this.countClick = 0;
        }
      } else {
        this.lastClickTime = new Date();
        this.countClick = 1;
      }
    }
  };

  async carregarObjetivosAprendizagemHabilidades(materiasSelecionadas) {
    try {
      this.setState({
        showLoaderModal: true,
        loaderModalText: 'Carregando objetivos de aprendizagem/habilidades...'
      });

      if (this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value && !this.state.dadosEnte.bnccNacional) {
        for (let diario of this.state.diarios) {
          const objetivosAprendizagemPromises = materiasSelecionadas.map(async (materia) => (new Promise(async (resolve) => {
            const objetivosAprendizagem = this.objetivosAprendizagemCampoExperienciaService
              .carregarObjetivosAprendizagemPorMateriaEquivalente(materia.value, diario.idEstruturaCurricular);
            resolve(objetivosAprendizagem);
          })));

          const objetivosAprendizagemResults = await Promise.all(objetivosAprendizagemPromises);
          const objetivosAprendizagem = [];

          objetivosAprendizagemResults.forEach((objetivosAprendizagemResult) => {
            objetivosAprendizagemResult.forEach((objetivoAprendizagem) => {
              Object.assign(objetivoAprendizagem, { value: objetivoAprendizagem.id, label: objetivoAprendizagem.descricao });
              objetivosAprendizagem.push(objetivoAprendizagem);
            });
          });

          Object.assign(diario, { objetivosAprendizagem });
        }
      } else {
        for (let diario of this.state.diarios) {
          const habilidadesPromises = materiasSelecionadas.map((materia) => (new Promise(async (resolve) => {
            const habilidades = this.habilidadesCompetenciasEspecificasService
              .carregarHabilidadesPorMateriaEquivalente(materia.value, diario.idEstruturaCurricular);
            resolve(habilidades);
          })));

          const habilidadesResults = await Promise.all(habilidadesPromises);
          const habilidades = [];

          habilidadesResults.forEach((habilidadesResult) => {
            habilidadesResult.forEach((habilidade) => {
              Object.assign(habilidade, { value: habilidade.id, label: habilidade.descricao });
              habilidades.push(habilidade);
            });
          });

          Object.assign(diario, { habilidades });
        }
      }
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao carregar objetivos de aprendizagem/habilidades',
      });
    }

    this.setState({
      showLoaderModal: false,
    });

  }

  conteudoPagina() {
    return this.state.selectDatas ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            {
              !this.state.turma.multiSeriado
                ? <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
                : <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}`}>{this.state.turma.turmaMultiseriado.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            }
            {
              !this.state.turma.multiSeriado
                ? <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}`}>{this.state.diario.nome}</Link></BreadcrumbItem>
                : <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}/diario`}>Diário único</Link></BreadcrumbItem>
            }
            {
              !this.state.turma.multiSeriado
                ? <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}/registroaulas`}>Registro de Aulas</Link></BreadcrumbItem>
                : <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}/diario/registroaulas/agrupado`}>Registro de Aulas</Link></BreadcrumbItem>
            }
            <BreadcrumbItem active>Registro de aula</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">

          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Registro de Aula
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="2">
                  <FormGroup className={'has-label has-danger'}>
                    <label>Data {this.state.dadosEnte.registroAulasPeriodo ? 'início' : ''} *</label>
                    <Select
                      isDisabled
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={`react-select primary ${this.state.dataState}`}
                      classNamePrefix="react-select"
                      value={this.state.dataSelecionada}
                      options={this.state.selectDatas}
                      placeholder="Data..."
                    />
                  </FormGroup>
                </Col>
                {
                  this.state.dadosEnte.registroAulasPeriodo && <Col md="2">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Data fim *</label>
                      <Select
                        isDisabled
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.dataFimState}`}
                        classNamePrefix="react-select"
                        value={this.state.dataFimSelecionada}
                        options={this.state.selectDatas}
                        placeholder="Data..."
                      />
                    </FormGroup>
                  </Col>
                }
                <Col md="8">
                  <FormGroup className={'has-label has-danger'}>
                    <label>{this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                      ? 'Campo de experiências' : 'Componente curricular'} *</label>
                    <Select
                      isDisabled
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={`react-select primary ${this.state.materiasState}`}
                      classNamePrefix="react-select"
                      closeMenuOnSelect={!this.state.dadosEnte.registroAulasPeriodo}
                      isMulti
                      value={this.state.materiasSelecionadas}
                      options={this.state.selectMaterias}
                      placeholder={this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                        ? 'Campo de experiências' : 'Componente curricular'}
                    />
                  </FormGroup>
                </Col>
                {
                  !this.state.dadosEnte.registroAulasPeriodo && <Col md="2">
                    <FormGroup className={`has-label ${this.state.numeroAulasState}`}>
                      <label>Nº de aulas</label>
                      <Input
                        disabled
                        value={this.state.numeroAulas}
                        decimalSeparator={false}
                        allowNegative={false}
                        style={{ textAlign: 'right' }}
                        onValueChange={(val) => {
                          this.setState({
                            numeroAulasState: '',
                            numeroAulas: parseInt(val.formattedValue),
                          });
                        }}
                        tag={NumberFormat} />
                      {this.state.numeroAulasState === 'has-danger' ? (
                        <label className="error">
                          {this.state.numeroAulasErro}
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                }
              </Row>
              {
                this.state.dadosEnte?.habilidadesRegistroAula && <>
                  {
                    this.state.diarios.map((diario, idx) => <Row key={idx}>
                      <Col>
                        {
                          (diario.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value && !this.state.dadosEnte.bnccNacional) ? <FormGroup className={'has-label has-danger'}>
                            <label>Objetivos de aprendizagem e desenvolvimento ({diario.curso})*</label>
                            <Select
                              isDisabled
                              noOptionsMessage={() => 'Nenhuma entrada'}
                              className={`react-select primary ${diario.objetivosAprendizagemSelecionadosState}`}
                              classNamePrefix="react-select"
                              placeholder=""
                              closeMenuOnSelect={false}
                              isMulti
                              value={diario.objetivosAprendizagemSelecionados}
                              options={diario.objetivosAprendizagem}
                            />
                          </FormGroup> : <FormGroup className={'has-label has-danger'}>
                            <label>{diario.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value ?
                              `${diario.curso} - Objetivos de aprendizagem e desenvolvimento *` :
                              `${diario.curso} - Habilidades *`}</label>
                            <Select
                              isDisabled
                              noOptionsMessage={() => 'Nenhuma entrada'}
                              className={`react-select primary ${diario.habilidadesSelecionadasState}`}
                              classNamePrefix="react-select"
                              placeholder=""
                              closeMenuOnSelect={false}
                              isMulti
                              value={diario.habilidadesSelecionadas}
                              options={diario.habilidades}
                            />
                          </FormGroup>
                        }
                      </Col>
                    </Row>
                    )
                  }
                </>
              }
              {
                (!this.state.dadosEnte?.registroAulasPeriodo || !this.state.dadosEnte?.habilidadesRegistroAula ||
                  (this.state.anoLetivo.ente.nome === 'Equador' && this.state.turma.curso.cicloEnsino > CicloEnsino.ENSINO_INFANTIL.value)) && <Row>
                  <Col>
                    <FormGroup className={`has-label ${this.state.objetoConhecimentoState}`}>
                      <label>{this.state.objetivo} *</label>
                      <Input
                        disabled
                        value={this.state.objetoConhecimento}
                        maxLength="500"
                        type="textarea"
                        style={{ minHeight: '300px' }} />
                      {this.state.objetoConhecimentoState === 'has-danger' ? (
                        <label className="error">
                          Informe o {this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                            ? 'objetivo de aprendizagem e desenvolvimento' : 'objeto de conhecimento'} da aula.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              }
              <Row>
                <Col>
                  <FormGroup className={`has-label ${this.state.metodologiaState}`}>
                    <label>Metodologia *</label>
                    <Input
                      disabled
                      onClick={this.handleClick}
                      value={this.state.metodologia}
                      type="textarea"
                      style={{ minHeight: '300px' }} />
                  </FormGroup>
                </Col>
              </Row>
              {
                this.state.dadosEnte.registroAulasPeriodo && <Row>
                  <Col>
                    <FormGroup className={'has-label'}>
                      <label>Avaliação</label>
                      <Input
                        disabled
                        value={this.state.avaliacao}
                        type="textarea"
                        style={{ minHeight: '300px' }} />
                    </FormGroup>
                  </Col>
                </Row>
              }
              {
                this.state.dadosEnte.registroAulasPeriodo && <Row>
                  <Col>
                    <FormGroup className={`has-label ${this.state.observacoesState}`}>
                      <label>Observações</label>
                      <Input
                        disabled
                        onClick={this.handleClick}
                        value={this.state.observacoes}
                        type="textarea"
                        style={{ minHeight: '300px' }} />
                    </FormGroup>
                  </Col>
                </Row>
              }
              {
                this.state.dadosEnte.habilidadesRegistroAula && !this.state.dadosEnte.registroAulasPeriodo && this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value && <Row>
                  <Col>
                    <FormGroup className={`has-label ${this.state.direitosAprendizagemState}`}>
                      <label>Direitos de aprendizagem *</label>
                      <Input
                        disabled
                        onClick={this.handleClick}
                        value={this.state.direitosAprendizagem}
                        type="textarea"
                        style={{ minHeight: '300px' }} />
                    </FormGroup>
                  </Col>
                </Row>
              }
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history.goBack()} >
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
        <MetodologiaModal
          isOpen={this.state.showMetodologiaModal}
          closeCallback={(metodologia) => {
            if (metodologia) {
              this.setState({
                metodologia: metodologia.label,
                metodologiaState: '',
              });
            }
            this.setState({ showMetodologiaModal: false });
          }} />
      </div>
    );
  }
}

export default withRouter(RegistroAulaMultiseriadoAgrupado);
