import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  FormGroup,
  UncontrolledTooltip,
} from 'reactstrap';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/animations/shift-away.css';

import moment from 'moment';
import 'rc-year-calendar/locales/rc-year-calendar.pt';
import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import MonthPicker from 'views/components/MonthPicker';
import CalendarModal from 'views/components/CalendarModal';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import FeriadosService from 'services/FeriadosService';
import DiasCanceladosService from 'services/DiasCanceladosService';
import DiasExtrasService from 'services/DiasExtrasService';
import FrequenciaService from 'services/FrequenciaService';
import RegistroSituacaoMatriculaService from 'services/RegistroSituacaoMatriculaService';
import ProfessorAuxiliarService from 'services/ProfessorAuxiliarService';

import Papel from 'assets/csv/papeis.json';
import Turno from 'assets/csv/turnos.json';
import TipoFeriado from 'assets/csv/tipos-feriado.json';
import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';
import SituacaoFrequencia from 'assets/csv/situacoes-frequencia.json';

class MesFrequencia extends React.Component {
  constructor(props) {
    super(props);

    this.tipos_feriados = [
      TipoFeriado.FERIADO_NACIONAL,
      TipoFeriado.FERIADO_ESTADUAL,
      TipoFeriado.FERIADO_MUNICIPAL,
      TipoFeriado.PONTO_FACULTATIVO,
    ];

    this.situacoes_matricula = [
      SituacaoMatricula.MATRICULADO,
      SituacaoMatricula.TRANSFERIDO,
      SituacaoMatricula.DESISTENTE,
      SituacaoMatricula.APROVADO,
      SituacaoMatricula.APROVADO_COM_DEPENDENCIA,
      SituacaoMatricula.REPROVADO_POR_NOTA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA_E_NOTA,
      SituacaoMatricula.NAO_MATRICULADO,
      SituacaoMatricula.FALECIDO,
    ];

    this.situacoes_frequencia = [
      SituacaoFrequencia.PRESENTE,
      SituacaoFrequencia.AUSENTE,
      SituacaoFrequencia.TRANSFERIDO,
      SituacaoFrequencia.DESISTENTE,
      SituacaoFrequencia.NAO_MATRICULADO,
      SituacaoFrequencia.FALECIDO,
    ];

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.feriadoMap = new Map();
    this.diaCanceladoMap = new Map();
    this.diaExtraMap = new Map();

    this.mesSelecionado = this.mesSelecionado.bind(this);
    this.adicionarPresencaTodos = this.adicionarPresencaTodos.bind(this);
    this.dataLimiteExcedida = this.dataLimiteExcedida.bind(this);

    this.state = {
      showAlert: false,
      showLoaderModal: false,
      buttonDisabled: false,

      viewDate: new Date(new Date().getFullYear(), 0, 1),
      mes: '',
      dias: [],

      error: null,
    };

    this.loginService = new LoginService();
    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.feriadosService = new FeriadosService();
    this.diasCanceladosService = new DiasCanceladosService();
    this.diasExtrasService = new DiasExtrasService();
    this.frequenciaService = new FrequenciaService();
    this.registroSituacaoMatriculaService = new RegistroSituacaoMatriculaService();
    this.professorAuxiliarService = new ProfessorAuxiliarService();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();

      const professoreAuxiliar = await this.professorAuxiliarService
        .carregarMeuProfessorAuxiliar();

      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turmaMultiseriado = await this.turmasService
        .carregarTurmaMultiseriado(this.props.match.params.idTurma);

			const turmas = await this.turmasService.carregarTurmasDaTurmaMultiseriado(turmaMultiseriado.id);

			Object.assign(turmaMultiseriado, { turmas });

      const fn0 = (t) => new Promise((resolve) => resolve(
        this.diariosService.carregarDiariosDaTurma(t.id),
      ));

      const actions0 = turmaMultiseriado.turmas.map(fn0);

      const diarios = await Promise.all(actions0);

      const diario = diarios[0][0];

      const feriados = await this.feriadosService
        .carregarFeriadosPorAnoLetivo(anoLetivo.id);

      const diasCancelados = await this.diasCanceladosService
        .carregarDiasCanceladosPorAnoLetivo(anoLetivo.id);

      const diasExtras = await this.diasExtrasService
        .carregarDiasExtrasPorAnoLetivo(anoLetivo.id);

      feriados.forEach((feriado) => this.feriadoMap.set(feriado.data.replace(/\//gi, '-'), true));

      diasCancelados.forEach((dia) => this.diaCanceladoMap.set(dia.data.replace(/\//gi, '-'), true));

      diasExtras.forEach((dia) => this.diaExtraMap.set(dia.data.replace(/\//gi, '-'), true));

      Object.assign(anoLetivo, { feriados, diasCancelados, diasExtras });

			const diasUteis = await this.anosLetivosService
				.carregarDiasUteisAnoLetivo(this.props.match.params.idAnoLetivo);

      const dias = await this.diariosService
        .carregarDiasDeAulaDoProfessor(diario.id);

      const matriculas = await this.turmasService
        .carregarListaFrequenciaTurmaMultiseriado(turmaMultiseriado.id);

      const fn1 = (matricula) => new Promise((resolve) => resolve(this.registroSituacaoMatriculaService
        .carregarRegistrosSituacaoMatricula(matricula.id)));
    
      const actions1 = matriculas.map(fn1);
      
      const registros = await Promise.all(actions1);
  
      matriculas.forEach((matricula, idx0) => {
        Object.assign(matricula, { registros: registros[idx0] });
        turmaMultiseriado.turmas.forEach((turma, idx) => {
          if (matricula.idTurma === turma.id) {
            Object.assign(
              matricula, { diario: diarios[idx][0] },
            );
          }
        });
      });

      const aulasLancadas = await this.frequenciaService
        .carregarAulasLancadasTurmaMultiseriado(turmaMultiseriado.id);

      this.setState({
        dadosEnte,
        anoLetivo,
        escola,
        turmaMultiseriado,
        diario,
        matriculas,
        aulasLancadas,
        viewDate: new Date(anoLetivo.ano, 0, 1),
        auxiliarBloqueado: this.props.role === Papel.PROFESSOR.value && professoreAuxiliar.auxiliar && dadosEnte.bloquearAlteracaoDiarioProfessorAuxiliar,
				diasUteis,
				dias,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  diaUtil(date) {
		const dateStr = moment(date).format('DD-MM-YYYY');
		const diaAula = !this.state.turmaMultiseriado.frequenciaRestrita || this.state.dias.some((d) => d === (date.getDay() - 1));
		const diaUtil = this.state.diasUteis.some((d) => d === dateStr);
		return diaAula && diaUtil;
	}

  getSituacaoAtual = (situacoes, dataStr) => {
    let i = 0;
    while (i < situacoes.length) {
      const dataSituacao = moment(situacoes[i].data, 'DD/MM/YYYY');
      const dataDia = moment(dataStr, 'DD/MM/YYYY');
      if (!dataDia.isBefore(dataSituacao)) {
        return situacoes[i].situacao;
      }
      i += 1;
    }

    return SituacaoMatricula.NAO_MATRICULADO.value;
  }

  async carregarDadosFrequenciaPeriodo(dias, primeiroDia, ultimoDia) {
    const primeiroDiaStr = primeiroDia.format('DD-MM-YYYY');
    const ultimoDiaStr = ultimoDia.format('DD-MM-YYYY');

    const frequenciaPeriodo = await this.frequenciaService.carregarFrequenciaTurmaMultiseriadoPeriodo(
      this.state.turmaMultiseriado.id, primeiroDiaStr, ultimoDiaStr,
    );

    dias.forEach((dia) => {
      if (dia.util) {
        const dataStr = dia.data.format('DD-MM-YYYY');
        const dataStr2 = dia.data.format('DD/MM/YYYY');
        this.state.matriculas.forEach((matricula) => {
          Object.assign(matricula, { [dataStr]: {} });
          const frequencia = [];
          frequenciaPeriodo.forEach((f) => {
            if (f.matricula.id === matricula.id && f.data === dataStr2) {
              frequencia.push(f);
            }
          });
          Object.assign(
            matricula[dataStr], {
            frequencia,
            situacao: this.getSituacaoAtual(matricula.registros, dataStr2),
          },
          );
        });
      }
    });

    this.setState({
      mesState: '',
      carregandoMes: false,
      dias,
    });
  }

  async mesSelecionado(mes) {
    this.setState({
      carregandoMes: true,
      mes: moment(mes).clone().endOf('month').format('MMMM').toUpperCase(),
    });
    
    const primeiroDia = moment({ year: this.state.anoLetivo.ano, month: moment(mes).clone().endOf('month').format('MM')-1 }).startOf('month');
    const ultimoDia = moment({ year: this.state.anoLetivo.ano, month: moment(mes).clone().endOf('month').format('MM')-1 }).endOf('month');

    let data = moment(primeiroDia).clone();
    const dias = [];
    while (!data.isAfter(ultimoDia)) {
      dias.push({ data, util: this.diaUtil(data.toDate()) });
      data = data.clone().add(1, 'd');
    }

    await this.carregarDadosFrequenciaPeriodo(dias, primeiroDia, ultimoDia);

  }

  getDataLimite(dataStr, lastTime) {
    if(!dataStr) return undefined;

    const dataLimite = moment(dataStr, 'DD/MM/YYYY');
    if(lastTime) {
      dataLimite.set('hours', 23);
      dataLimite.set('minutes', 59);
      dataLimite.set('seconds', 59);
    }

    return dataLimite;
  }

  dataLimiteExcedida(mData) {
    if(!this.state.dadosEnte.limitesBimestrais) return false;
    
    const today = moment();

    const dataInicioBimestre1 = this.getDataLimite(this.state.anoLetivo.dataInicioBimestre1);
    const dataInicioBimestre2 = this.getDataLimite(this.state.anoLetivo.dataInicioBimestre2);
    const dataInicioBimestre3 = this.getDataLimite(this.state.anoLetivo.dataInicioBimestre3);
    const dataInicioBimestre4 = this.getDataLimite(this.state.anoLetivo.dataInicioBimestre4);

    const dataFimBimestre1 = this.getDataLimite(this.state.anoLetivo.dataFimBimestre1, true);
    const dataFimBimestre2 = this.getDataLimite(this.state.anoLetivo.dataFimBimestre2, true);
    const dataFimBimestre3 = this.getDataLimite(this.state.anoLetivo.dataFimBimestre3, true);
    const dataFimBimestre4 = this.getDataLimite(this.state.anoLetivo.dataFimBimestre4, true);

    const dataLimiteBimestre1 = this.getDataLimite(this.state.anoLetivo.dataLimiteBimestre1, true);
    const dataLimiteBimestre2 = this.getDataLimite(this.state.anoLetivo.dataLimiteBimestre2, true);
    const dataLimiteBimestre3 = this.getDataLimite(this.state.anoLetivo.dataLimiteBimestre3, true);
    const dataLimiteBimestre4 = this.getDataLimite(this.state.anoLetivo.dataLimiteBimestre4, true);
    
    if(dataLimiteBimestre1 && today.isAfter(dataLimiteBimestre1) && mData.isBetween(dataInicioBimestre1, dataFimBimestre1, undefined, '[]')) {
      return true;
    } else if(dataLimiteBimestre2 && today.isAfter(dataLimiteBimestre2) && mData.isBetween(dataInicioBimestre2, dataFimBimestre2, undefined, '[]')) {
      return true;
    } else if(dataLimiteBimestre3 && today.isAfter(dataLimiteBimestre3) && mData.isBetween(dataInicioBimestre3, dataFimBimestre3, undefined, '[]')) {
      return true;
    } else if(dataLimiteBimestre4 && today.isAfter(dataLimiteBimestre4) && mData.isBetween(dataInicioBimestre4, dataFimBimestre4, undefined, '[]')) {
      return true;
    }
    return false;
  }

  adicionarRemoverFrequencia(matricula, dia, situacao) {
    if (this.props.role !== Papel.PROFESSOR.value) return;
    if (this.dataLimiteExcedida(dia.data)) return;
    
    if (!this.diaUtil(dia.data.toDate())) return;
    if (matricula[dia.data.format('DD-MM-YYYY')].situacao !== SituacaoMatricula.MATRICULADO.value) return;

    if (matricula[dia.data.format('DD-MM-YYYY')].frequencia.length < 10) {
      const novaFrequencia = {
        situacao,
        data: dia.data.format('DD/MM/YYYY'),
        ordem: matricula[dia.data.format('DD-MM-YYYY')].frequencia.length + 1,
      };

      Object.assign(matricula[dia.data.format('DD-MM-YYYY')],
        { frequencia: matricula[dia.data.format('DD-MM-YYYY')].frequencia.concat([novaFrequencia]) });
    } else {
      Object.assign(matricula[dia.data.format('DD-MM-YYYY')],
        { frequencia: matricula[dia.data.format('DD-MM-YYYY')].frequencia.filter((freq) => freq.situacao !== situacao) });
    }
    Object.assign(dia, { erro: false });
    this.forceUpdate();
  }

  adicionarRemoverFrequenciaTodosOsDias(matricula, situacao) {
    this.state.dias.forEach((dia) => {
      if (this.diaUtil(dia.data.toDate())) {
        this.adicionarRemoverFrequencia(matricula, dia, situacao);
      }
    });

    this.forceUpdate();
  }

  adicionarRemoverFrequenciaTodosOsAlunos(dia, situacao) {
    if (!this.diaUtil(dia.data.toDate())) return;
    this.state.matriculas.forEach((matricula) => {
      this.adicionarRemoverFrequencia(matricula, dia, situacao);
    });

    this.forceUpdate();
  }

  adicionarPresencaTodos() {
    this.state.matriculas.forEach((matricula) =>
      this.adicionarRemoverFrequenciaTodosOsDias(
        matricula, SituacaoFrequencia.PRESENTE.value
      )
    );
  }

  validarCampos() {
    let ret = true;

    if (!this.state.mes) {
      this.setState({ mesState: 'has-danger' });
      ret = false;
    }

    let erroQuantidade = false;
    this.state.dias.forEach((dia) => {
      if (this.diaUtil(dia.data.toDate())) {
        let qtd = -1;
        const diaStr = dia.data.format('DD-MM-YYYY');

        this.state.matriculas.forEach((matricula) => {
          if (qtd <= 0 && matricula[diaStr].situacao === SituacaoMatricula.MATRICULADO.value) {
            qtd = matricula[diaStr].frequencia.length;
          }
        });

        this.state.matriculas.forEach((matricula) => {
          if (matricula[diaStr].situacao === SituacaoMatricula.MATRICULADO.value
            && matricula[diaStr].frequencia.length !== qtd) {
              erroQuantidade = true;
              Object.assign(dia, { erro: true });
            this.setState({ quantidadeState: 'has-danger' });
            ret = false;
          }
        });
      }
    });

    if (erroQuantidade) {
      this.setState({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro:A soma das presenças e ausências em um mesmo dia deve ser igual para todos os alunos.',
      });
    }

    return ret;
  }

  async lancarFrequencia() {
    this.qtd = -1;

    if (!this.validarCampos()) return;

    try {
      this.state.dias.forEach((dia) => {
        if (this.diaUtil(dia.data.toDate())) {
          const diaStr = dia.data.format('DD-MM-YYYY');
          this.state.matriculas.forEach((matricula) => {
            if (matricula[diaStr].situacao !== SituacaoMatricula.MATRICULADO.value) {
              Object.assign(matricula[diaStr], { frequencia: [] });
            } else {
              this.qtd = matricula[diaStr].frequencia.length;
            }
          });

          this.state.matriculas.forEach((matricula) => {
            if (matricula[diaStr].situacao !== SituacaoMatricula.MATRICULADO.value) {
              let situacao;
              switch (matricula[diaStr].situacao) {
                case SituacaoMatricula.TRANSFERIDO.value:
                  situacao = SituacaoFrequencia.TRANSFERIDO.value;
                  break;
                case SituacaoMatricula.DESISTENTE.value:
                  situacao = SituacaoFrequencia.DESISTENTE.value;
                  break;
                case SituacaoMatricula.FALECIDO.value:
                  situacao = SituacaoFrequencia.FALECIDO.value;
                  break;
                case SituacaoMatricula.NAO_MATRICULADO.value:
                  situacao = SituacaoFrequencia.NAO_MATRICULADO.value;
                  break;
                default:
                  situacao = SituacaoFrequencia.PRESENTE.value;
                  break;
              }

              const frequencia = [];
              for (let i = 0; i < this.qtd; i += 1) {
                const novaFrequencia = {
                  situacao,
                  data: dia.data.format('DD/MM/YYYY'),
                  ordem: frequencia.length + 1,
                  diario: { id: matricula.diario.id },
                };
                frequencia.push(novaFrequencia);
              }
              Object.assign(matricula[diaStr], { frequencia });
            } else {
              this.qtd = matricula[diaStr].frequencia.length;
            }
          });
        }
      });

      const arr = [];
      this.state.dias.forEach((dia) => {
        if (this.diaUtil(dia.data.toDate())) {
          const diaStr = dia.data.format('DD-MM-YYYY');
          const freqsDia = [];
          this.state.matriculas.forEach((matricula) => {
            const freqMat = [];
            matricula[diaStr].frequencia.forEach((freq) => {
              freqMat.push({
                matricula: {
                  id: matricula.id,
                },
                situacao: freq.situacao,
                ordem: freq.ordem,
                diario: { id: matricula.diario.id },
              });
            });
            freqsDia.push(freqMat);
          });
          arr.push({
            dia: diaStr,
            frequencias: freqsDia,
          });
        }
      });

      this.setState({
        buttonDisabled: true,
        showAlert: false,
      });

      await this.frequenciaService
        .cadastrarFrequenciaMesMultiseriado(this.state.turmaMultiseriado.id, arr);

      const aulasLancadas = await this.frequenciaService
        .carregarAulasLancadasTurmaMultiseriado(this.state.turmaMultiseriado.id);

      this.setState({
        aulasLancadas,
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Frequências cadastradas com sucesso.',
      });
    } catch (e) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao lançar frequências',
      });
    }
    this.setState({ quantidadeState: '' });
  }

  getClassName(dia) {
    let cname = '';
    if(!dia.util) {
      cname = 'dia-nao-util';
    } else if(dia.erro) {
      cname = 'dia-erro';
    } else {
      cname = 'dia-util';
    };

    return cname;
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turmaMultiseriado.id}`}>{this.state.turmaMultiseriado.nome} - {this.turnos[this.state.turmaMultiseriado.turno].label}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turmaMultiseriado.id}/diario`}>Diário único</Link></BreadcrumbItem>
            <BreadcrumbItem active>Frequência por mês</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Frequência por mês
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="12">
                  <h5>
                    &emsp;Aulas a lançar:&nbsp;
                    {
                      this.state.diario.aulasALancar || this.state.diario.aulasALancar === 0
                        ? `${this.state.aulasLancadas} / ${this.state.diario.aulasALancar}`
                        : 'Não informado'
                    }
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <FormGroup className={`has-label ${this.state.mesState}`}>
                    <label>Mês *</label>
                    <MonthPicker
                      viewDate={this.state.viewDate}
                      value={this.state.mes}
                      onChange={(mes) => {
                        this.mesSelecionado(mes);
                      }}
                      onClickCalendarButton={() => {
                        this.setState({
                          showCalendarModal: true,
                          quantidadeState: '',
                          mesState: '',
                        });
                      }}
                    />
                    {this.state.mesState === 'has-danger' ? (
                      <label className="error">
                        Informe o mês.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
                {
                  this.props.role === Papel.PROFESSOR.value && this.state.mes && <Col>
                    <div style={{ display: 'flex', height: '100%', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                      <Button
                        id="btnFrequenciaAll"
                        disabled={this.state.buttonDisabled}
                        color="primary"
                        onClick={() => this.adicionarPresencaTodos()} >
                        +1 Presença
                      </Button>
                      <UncontrolledTooltip placement="bottom" target="btnFrequenciaAll" delay={0}>
                        Adicionar uma presença para todos os alunos em todos os dias
                      </UncontrolledTooltip>
                    </div>
                  </Col>
                }
              </Row>
              <br />
              <Row>
                <Col>
                  <FormGroup className={`has-label ${this.state.quantidadeState}`}>
                    {
                      this.state.carregandoMes ? <div align="center" style={{ padding: 30 }}>
                        <Loader
                          type="Oval"
                          color="#34B5B8"
                          height="50"
                          width="50" /> </div> : this.state.dias
                          && this.state.dias.length > 0
                      && <div className="frequencia-mes">
                        <PerfectScrollbar className="sisa-perfect-scrollbar">
                        <table>
                          <thead>
                            <tr>
                              <th className="nome-aluno">
                                Nome
                              </th>
                              <th></th>
                              {
                                this.state.dias.map((dia, idx) => <th
                                  className={dia.util ? 'dia-util' : 'dia-nao-util'}
                                  key={idx}
                                  onClick={() => this.adicionarRemoverFrequenciaTodosOsAlunos(
                                    dia, SituacaoFrequencia.PRESENTE.value,
                                  )}>
                                  {dia.data.date()}
                                </th>)
                              }
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.matriculas
                                .map((matricula, idx) => <React.Fragment key={idx}>
                                  <tr className="line-break">
                                    <td colSpan={this.state.dias.length + 2}></td>
                                  </tr>
                                  <tr>
                                    <td rowSpan="2" className="nome-aluno">
                                      {matricula.usuario.nome}
                                    </td>
                                    <td
                                      className="label dia-util"
                                      onClick={() => this.adicionarRemoverFrequenciaTodosOsDias(
                                        matricula, SituacaoFrequencia.PRESENTE.value,
                                      )}>
                                      P
                                    </td>
                                    {
                                      this.state.dias.map((dia, idx2) => <td
                                        className={this.getClassName(dia)}
                                        key={idx2}
                                        onClick={() => this.adicionarRemoverFrequencia(
                                          matricula, dia, SituacaoFrequencia.PRESENTE.value,
                                        )}>
                                        {dia.util && <>{
                                          matricula[dia.data.format('DD-MM-YYYY')].situacao === SituacaoMatricula.MATRICULADO.value
                                            ? matricula[dia.data.format('DD-MM-YYYY')].frequencia
                                              .reduce((ac, f) => ac + (f.situacao
                                                === SituacaoFrequencia.PRESENTE.value ? 1 : 0), 0)
                                            : this.situacoes_matricula[matricula[dia.data.format('DD-MM-YYYY')].situacao].label2
                                        }</>}
                                      </td>)
                                    }
                                  </tr>
                                  <tr>
                                    <td
                                      className="label dia-util"
                                      onClick={() => this.adicionarRemoverFrequenciaTodosOsDias(
                                        matricula, SituacaoFrequencia.AUSENTE.value,
                                      )}>
                                      F
                                    </td>
                                    {
                                      this.state.dias.map((dia, idx2) => <td
                                        className={`${this.getClassName(dia)}`}
                                        key={idx2}
                                        onClick={() => this.adicionarRemoverFrequencia(
                                          matricula, dia, SituacaoFrequencia.AUSENTE.value,
                                        )}>
                                        {dia.util && <>{
                                          matricula[dia.data.format('DD-MM-YYYY')].situacao === SituacaoMatricula.MATRICULADO.value || matricula[dia.data.format('DD-MM-YYYY')].frequencia.reduce((ac, f) => ac + (f.situacao === SituacaoFrequencia.AUSENTE.value ? 1 : 0), 0) > 0
                                            ? matricula[dia.data.format('DD-MM-YYYY')].frequencia
                                              .reduce((ac, f) => ac + (f.situacao
                                                === SituacaoFrequencia.AUSENTE.value ? 1 : 0), 0)
                                            : this.situacoes_matricula[matricula[dia.data.format('DD-MM-YYYY')].situacao].label2
                                        }</>}
                                      </td>)
                                    }
                                  </tr>
                                </React.Fragment>)
                            }

                          </tbody>
                        </table>
                        <br/>
                        </PerfectScrollbar>
                      </div>
                    }
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history
                      .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turmaMultiseriado.id}/diario/frequencia`)} >
                    Voltar
                  </Button>
                </Col>
                {
                  this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado && <Col className="text-right">
                    <Button
                      disabled={this.state.buttonDisabled}
                      color="primary"
                      onClick={() => this.lancarFrequencia()} >
                      Lançar frequência
                    </Button>
                  </Col>
                }
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <CalendarModal
        anoLetivo={this.state.anoLetivo}
        isOpen={this.state.showCalendarModal}
        toggle={() => this.setState({ showCalendarModal: !this.state.showCalendarModal })} />
      <LoaderModal
        isOpen={this.state.buttonDisabled}
        text='Cadastrando frequência...' />
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default withRouter(MesFrequencia);
