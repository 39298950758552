import React from 'react';

import {
  Button,
  Col,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';

import TurmasService from 'services/TurmasService';
import AwsService from 'services/AwsService';

class MuraisFotosTurma extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      erro: false,
      loading: true,

      muraisFotos: [],
    };

    this.turmasService = new TurmasService();
    this.awsService = new AwsService();

  }

  async componentDidMount() {
    try {
      await this.carregarMuraisFotos();
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarMuraisFotos() {
    this.setState({
      loading: true,
    });

    const _muraisFotos = await this.turmasService.carregarMuraisFotos(this.props.turma.id);

    const urlPromises = _muraisFotos.map((muralFotos) => new Promise(async (resolve) => {
      const url = await this.awsService.getPresignedUrl(muralFotos.url);
      resolve(url);
    }));

    const urls = await Promise.all(urlPromises);

    const muraisFotos = _muraisFotos.map((muralFotos, idx) => ({
      ...muralFotos,
      menuAberto: false,
      src: urls[idx],
    }));

    this.setState({
      muraisFotos,
      loading: false,
    });
  }

  atualizarMuralFotos(muralFotos) {
    this.props.history.push(`${this.props.layout}/anosletivos/${this.props.anoLetivo.id}/escola/${this.props.escola.id}/turma/${this.props.turma.id}/muraisfotos/edicao/${muralFotos.id}`)
    this.setState({ menuAberto: null })
  }

  confirmarRemocaoMuralFotos(muralFotos) {
    this.setState({
      showConfirmModal: true,
      confirmModalText: 'Confirme a remoção do mural de fotos.',
      muralFotosSelecionado: muralFotos,
    })
  }

  async removerMuralFotos() {
    this.props.callbackMessage({ showAlert: false })

    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Removendo mural de fotos...',
    });

    try {
      await this.turmasService
        .removerMuralFotos(this.state.muralFotosSelecionado.id);

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Mural de fotos removido com sucesso',
      });

      this.setState({
        showLoaderModal: false,
      });

      await this.carregarMuraisFotos();
    } catch (msg) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover mural de fotos',
      });

      this.setState({
        showLoaderModal: false,
      });
    }
  }

  abrirMuralFotos(muralFotos) {
    this.props.history.push(`${this.props.layout}/anosletivos/${this.props.anoLetivo.id}` +
      `/escola/${this.props.escola.id}/turma/${this.props.turma.id}/muraisfotos/${muralFotos.id}`);
  }

  conteudoPagina() {
    return this.state.loading ? <div align="center" style={{ padding: 30 }}>
      <Loader
        type="Oval"
        color="#34B5B8"
        height="50"
        width="50" />
    </div> : <>
      <Row>
        {
          this.state.muraisFotos.map((muralFotos, idx) => <Col md="2" className="mt-4" key={idx} onClick={(e) => this.abrirMuralFotos(muralFotos)}>
            <div className="album-container">
              <img src={muralFotos.src} alt="Imagem Recortada" />
              <div className="album-label album-nome">{muralFotos.nome}</div>
              <div className="album-label album-quantidade-fotos">
                {muralFotos.quantidadeFotos} {muralFotos.quantidadeFotos > 1 ? "Fotos" : "Foto"}
              </div>
              <div className="album-label album-data">{muralFotos.data}</div>
              {
                muralFotos.autor.id === this.props.dadosUsuario.idUsuario && <UncontrolledDropdown className="album-menu-dropdown">
                  <DropdownToggle
                    className="album-menu-dropdown-toggle"
                    aria-haspopup={true}
                    color="transparent"
                    data-toggle="collapse"
                    id="acoesMateriaDropdownMenuLink"
                    onClick={(evt) => evt.stopPropagation()}
                    nav>
                    <Button className="album-menu-dropdown-toggle-button" size="sm">⋮</Button>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      onClick={(e) => {
                        e.stopPropagation();
                        this.atualizarMuralFotos(muralFotos);
                      }}>
                      Alterar
                    </DropdownItem>
                    <DropdownItem
                      onClick={(e) => {
                        e.stopPropagation();
                        this.confirmarRemocaoMuralFotos(muralFotos);
                      }} >
                      Remover
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              }
            </div>
          </Col>)
        }
      </Row>
      <Row>
        <Col className="text-left">
          <Button
            color="primary"
            onClick={() => this.props.history
              .push(`${this.props.layout}/anosletivos/${this.props.anoLetivo.id}/escola/${this.props.escola.id}`)} >
            Voltar
          </Button>
        </Col>
        <Col className="text-right">
          <Button
            color="primary"
            onClick={() => this.props.history
              .push(`${this.props.layout}/anosletivos/${this.props.anoLetivo.id}/escola/${this.props.escola.id}/turma/${this.props.turma.id}/muraisfotos/cadastro`)} >
            Cadastrar mural de fotos
          </Button>
        </Col>
      </Row>
      <ConfirmModal
        isOpen={this.state.showConfirmModal}
        callback={(confirm) => {
          this.setState({ showConfirmModal: false });
          if (confirm) {
            this.removerMuralFotos();
          } else {
            this.setState({ muralFotosSelecionado: null });
          }
        }}
        text='Confirme a remoção do mural de fotos' />
      <LoaderModal
        isOpen={this.state.showLoaderModal}
        text={`${this.state.loaderModalText}`} />
    </>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <div align="center" style={{ margin: 50 }}>
            Erro ao buscar informações da página
          </div>
        }
      </div>
    );
  }
}

export default MuraisFotosTurma;
