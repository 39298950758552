import { withRouter } from 'hoc/withRouter';
import React from 'react';

import { Link } from 'react-router-dom';

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import uniqid from 'uniqid';

import Select from 'react-select';
import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import EntesService from 'services/EntesService';
import AnosLetivosService from 'services/AnosLetivosService';
import AvaliacaoConceitualService from 'services/AvaliacaoConceitualService';
import DiariosService from 'services/DiariosService';
import EscolasService from 'services/EscolasService';
import MateriasService from 'services/MateriasService';
import TurmasService from 'services/TurmasService';

import CicloEnsino from 'assets/csv/ciclos-ensino.json';
import Papel from 'assets/csv/papeis.json';
import SituacaoAvaliacaoConceitual from 'assets/csv/situacoes-avaliacao-conceitual';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';
import Turno from 'assets/csv/turnos.json';
import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';

class AvaliacaoConceitual extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.situacoes = [
      SituacaoAvaliacaoConceitual.DESENVOLVIDO,
      SituacaoAvaliacaoConceitual.PARCIALMENTE_DESENVOLVIDO,
      SituacaoAvaliacaoConceitual.NAO_DESENVOLVIDO,
      SituacaoAvaliacaoConceitual.NAO_INFORMADO,
    ];

    this.situacoesMatricula = [
      SituacaoMatricula.MATRICULADO,
      SituacaoMatricula.TRANSFERIDO,
      SituacaoMatricula.DESISTENTE,
      SituacaoMatricula.APROVADO,
      SituacaoMatricula.APROVADO_COM_DEPENDENCIA,
      SituacaoMatricula.REPROVADO_POR_NOTA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA_E_NOTA,
      SituacaoMatricula.NAO_MATRICULADO,
      SituacaoMatricula.FALECIDO,
    ];

    this.salvarAvaliacoesConceituais = this.salvarAvaliacoesConceituais.bind(this);

    this.state = {
      showAlert: false,
      showLoaderModal: false,

      error: null,
    };

    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.materiasService = new MateriasService();
    this.avaliacaoConceitualService = new AvaliacaoConceitualService();
  }

  async componentDidMount() {
    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();

      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const materias = await this.diariosService
        .carregarMateriasDoProfessor(this.props.match.params.idDiario);

      const selectMaterias = [];
      materias.forEach((materia) => {
        selectMaterias.push({
          label: materia.nome,
          value: materia.id,
        });
      });

      const materiaSelecionada = {
        label: materias[0].nome,
        value: materias[0].id,
      };

      await this.carregarAvaliacoesConceituais(materiaSelecionada.value);

      this.bimestres = dadosEnte.avaliacaoConceitualSemestral ? [1, 3] : [0, 1, 2, 3];

      this.setState({
        dadosEnte,
        anoLetivo,
        escola,
        turma,
        diario,
        selectMaterias,
        materiaSelecionada,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarAvaliacoesConceituais(idMateria) {
    try {
      this.setState({ carregandoAvaliacoes: true });

      const matriculas = await this.diariosService
        .carregarMatriculasNaMateria(this.props.match.params.idDiario, idMateria);

      const avaliacoesConceituais = await this.avaliacaoConceitualService
        .carregarAvaliacoesConceituaisMateria(this.props.match.params.idDiario, idMateria);

      const situacoesBimestres = [];
      for (let bimestre = 1; bimestre <= 4; ++bimestre) {
        const avaliacoesConceituaisBimestre = [];
        matriculas.forEach((matricula) => {
          const avaliacaoConceitualBimestre = avaliacoesConceituais.find((avaliacaoConceitual) => avaliacaoConceitual.matricula.id === matricula.id && avaliacaoConceitual.bimestre === bimestre) || ({
            matricula,
            bimestre,
            materia: { id: idMateria },
            diario: { id: this.props.match.params.idDiario },
            situacao: SituacaoAvaliacaoConceitual.NAO_INFORMADO.value,
          });
          avaliacoesConceituaisBimestre.push(avaliacaoConceitualBimestre);
        });
        situacoesBimestres.push(avaliacoesConceituaisBimestre);
      }

      this.setState({
        matriculas,
        situacoesBimestres,
        carregandoAvaliacoes: false,
      });

    } catch (msg) {
      this.setState({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao carregar avaliações conceituais',
      });
    }
  }

  async salvarAvaliacoesConceituais() {
    this.setState({
      showAlert: false,
      showLoaderModal: true,
    });

    try {
      await this.avaliacaoConceitualService.salvarAvaliacoesConceituais(
        this.props.match.params.idDiario,
        this.state.materiaSelecionada.value,
        this.state.situacoesBimestres,
      );

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Avaliações conceituais da matéria salvas com sucesso.',
      });

      this.carregarAvaliacoesConceituais(this.state.materiaSelecionada.value);
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao salvar avaliações conceituais da matéria.',
      });
    }
  }

  voltar() {
    if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
      return `${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`
        + `/diario/${this.state.diario.id}`;
    }
    return `${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}`
      + `/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao`;
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={this.voltar()}>{this.state.diario.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Avaliação conceitual</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                {this.state.titulo2}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup className={'has-label has-danger'}>
                    <label>{this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                      ? 'Campo de experiências' : 'Componente curricular'}</label>
                    <Select
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={'react-select primary'}
                      classNamePrefix="react-select"
                      value={this.state.materiaSelecionada}
                      onChange={async (event) => {
                        this.setState({ materiaSelecionada: event })
                        await this.carregarAvaliacoesConceituais(event.value);
                      }}
                      options={this.state.selectMaterias}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  {
                    this.state.carregandoAvaliacoes ? <div align="center" style={{ padding: 30 }}>
                      <Loader
                        type="Oval"
                        color="#34B5B8"
                        height="50"
                        width="50" />
                    </div> : <Table>
                      <thead className="text-primary">
                        <tr>
                          <th rowSpan="2" style={{ width: '30%' }}>Aluno</th>
                          {
                            this.state.dadosEnte.avaliacaoConceitualSemestral ?
                              <th colSpan="2" className="text-center" style={{ width: '70%' }}>Situação</th> :
                              <th colSpan="4" className="text-center" style={{ width: '70%' }}>Situação</th>
                          }
                        </tr>
                        {
                          this.state.dadosEnte.avaliacaoConceitualSemestral ? <tr>
                            <th className="text-center">1º Semestre</th>
                            <th className="text-center">2º Semestre</th>
                          </tr> : <tr>
                            <th className="text-center">1º Bimestre</th>
                            <th className="text-center">2º Bimestre</th>
                            <th className="text-center">3º Bimestre</th>
                            <th className="text-center">4º Bimestre</th>
                          </tr>
                        }
                      </thead>
                      <tbody>
                        {
                          this.state.matriculas.map((matricula, idxm) => <tr
                            key={uniqid()}>
                            <td><div className="preserve-format">{matricula.usuario.nome} ({this.situacoesMatricula[matricula.situacao].label})</div></td>
                            {
                              this.bimestres.map((bimestre) => <td key={uniqid()} className="text-center">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    aria-haspopup={true}
                                    color={this.situacoes[this.state.situacoesBimestres[bimestre][idxm].situacao].color}
                                    data-toggle="collapse"
                                    id="acoesAvaliacoesConceituaisDropdownMenuLink"
                                    onClick={(evt) => evt.stopPropagation()}
                                    nav>
                                    <Button color={this.situacoes[this.state.situacoesBimestres[bimestre][idxm].situacao].color}>
                                      {this.situacoes[this.state.situacoesBimestres[bimestre][idxm].situacao].label} <b className="caret" />
                                    </Button>
                                  </DropdownToggle>
                                  <DropdownMenu aria-labelledby="acoesAvaliacoesConceituaisDropdownMenuLink" right>
                                    <DropdownItem
                                      onClick={() => {
                                        if (this.props.role !== Papel.PROFESSOR.value) return;
                                        Object.assign(this.state.situacoesBimestres[bimestre][idxm], {
                                          ...this.state.situacoesBimestres[bimestre][idxm],
                                          situacao: SituacaoAvaliacaoConceitual.NAO_INFORMADO.value
                                        });
                                        this.forceUpdate();
                                      }}>
                                      {SituacaoAvaliacaoConceitual.NAO_INFORMADO.label}
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => {
                                        if (this.props.role !== Papel.PROFESSOR.value) return;
                                        Object.assign(this.state.situacoesBimestres[bimestre][idxm], {
                                          ...this.state.situacoesBimestres[bimestre][idxm],
                                          situacao: SituacaoAvaliacaoConceitual.DESENVOLVIDO.value
                                        });
                                        this.forceUpdate();
                                      }}>
                                      {SituacaoAvaliacaoConceitual.DESENVOLVIDO.label}
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => {
                                        if (this.props.role !== Papel.PROFESSOR.value) return;
                                        Object.assign(this.state.situacoesBimestres[bimestre][idxm], {
                                          ...this.state.situacoesBimestres[bimestre][idxm],
                                          situacao: SituacaoAvaliacaoConceitual.PARCIALMENTE_DESENVOLVIDO.value
                                        });
                                        this.forceUpdate();
                                      }}>
                                      {SituacaoAvaliacaoConceitual.PARCIALMENTE_DESENVOLVIDO.label}
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => {
                                        if (this.props.role !== Papel.PROFESSOR.value) return;
                                        Object.assign(this.state.situacoesBimestres[bimestre][idxm], {
                                          ...this.state.situacoesBimestres[bimestre][idxm],
                                          situacao: SituacaoAvaliacaoConceitual.NAO_DESENVOLVIDO.value
                                        });
                                        this.forceUpdate();
                                      }}>
                                      {SituacaoAvaliacaoConceitual.NAO_DESENVOLVIDO.label}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>)
                            }
                          </tr>)
                        }
                      </tbody>
                    </Table>
                  }
                </Col>
              </Row>
              <Row>
                <Col className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history.push(this.voltar())} >
                    Voltar
                  </Button>
                </Col>
                <Col className="text-right">
                  {
                    this.props.role === Papel.PROFESSOR.value
                    && <Button
                      color="primary"
                      disabled={this.state.buttonDisabled}
                      onClick={this.salvarAvaliacoesConceituais} >
                      Salvar avaliações conceituais
                    </Button>
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text="Salvando habilidades trabalhadas..." />
      </div>
    );
  }
}

export default withRouter(AvaliacaoConceitual);
