import React from 'react';

import {
  Modal,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import TextareaAutosize from 'react-textarea-autosize';

import UsuarioService from 'services/UsuarioService';

import Sexo from 'assets/csv/sexos.json';
import Etnia from 'assets/csv/etnias.json';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

class FichaAlunoModal extends React.Component {
  constructor(props) {
    super(props);

    this.sexos = [
      Sexo.MASCULINO,
      Sexo.FEMININO,
    ];

    this.etnias = [
      Etnia.BRANCA,
      Etnia.PRETA,
      Etnia.PARDA,
      Etnia.AMARELA,
      Etnia.NAO_DECLARADA,
      Etnia.INDIGENA,
    ];

    this.pnes = [
      { label: 'Não', value: false },
      { label: 'Sim', value: true },
    ];

    this.opcoesBolsaFamilia = [
      { label: 'Não', value: false },
      { label: 'Sim', value: true },
    ];

    this.opcoesUtilizaTransporte = [
      { label: 'Não', value: false },
      { label: 'Sim', value: true },
    ];

    this.state = {
      loading: true,
      erro: null,
    };

    this.usuarioService = new UsuarioService();
  }

  componentDidUpdate(prevProps) {
    if (this.props.idAluno !== prevProps.idAluno) {
      this.carregarDados(this.props.idAluno);
    }
  }

  async carregarDados(idAluno) {
    this.setState({ erro: false, loading: true });
    try {
      const aluno = await this.usuarioService
        .carregarUsuario(idAluno);

      const foto = await this.usuarioService.carregarFoto(aluno.id);

      this.setState({
        foto,
        aluno,
        utilizaTransporte: this.opcoesUtilizaTransporte[aluno.utilizaTransporte ? 1 : 0].label,
        loading: false,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return (
      this.state.loading ? <>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </> : <>
        <Row>
          <Col style={{ marginBottom: 20 }}>
            <FormGroup className={'has-label'}>
              <div>
                <img
                  alt="..."
                  src={`data:image/jpeg;base64,${this.state.foto}`}
                  width={120}
                  height={160}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Tabs>
          <TabList>
            <Tab>Ficha do aluno</Tab>
            {
              this.state.aluno.pne && <Tab>Laudo PNE</Tab>
            }
          </TabList>
          <TabPanel>
            <div className="grupo-info">
              <Row>
                <Col>
                  <h5>Dados do Estudante</h5>
                </Col>
              </Row>
              <Row>
                <Col md="5">
                  <FormGroup>
                    <label>Nome</label>
                    <Input
                      disabled
                      value={this.state.aluno.nome}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <label>Telefone</label>
                    <Input
                      disabled
                      value={this.state.aluno.credenciais.telefone}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <label>Data de nascimento</label>
                    <Input
                      disabled
                      value={this.state.aluno.dataNascimento}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <label>Naturalidade </label>
                    <Input
                      disabled
                      value={this.state.aluno.naturalidade}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <label>Endereço</label>
                    <Input
                      disabled
                      value={this.state.aluno.endereco}
                      name="enderecoAluno"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <label>Sexo</label>
                    <Input
                      disabled
                      value={this.sexos[this.state.aluno.sexo].label}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <label>Etnia</label>
                    <Input
                      disabled
                      value={this.etnias[this.state.aluno.etnia].label}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <label>Necessidades especiais</label>
                    <Input
                      disabled
                      value={this.pnes[this.state.aluno.pne ? 1 : 0].label}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <FormGroup>
                    <label>ID Censo</label>
                    <Input
                      disabled
                      value={this.state.aluno.inep}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <label>NIS</label>
                    <Input
                      disabled
                      value={this.state.aluno.nis}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>Cartão SUS</label>
                    <Input
                      disabled
                      value={this.state.aluno.cartaoSUS}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <label>Bolsa Família</label>
                    <Input
                      disabled
                      value={this.opcoesBolsaFamilia[this.state.aluno.bolsaFamilia ? 1 : 0].label}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="5">
                  <FormGroup>
                    <label>Ponto de referência</label>
                    <Input
                      disabled
                      value={this.state.aluno.referencia}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="5">
                  <FormGroup>
                    <label>Nome social</label>
                    <Input
                      disabled
                      value={this.state.aluno.nomeSocial}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <label>Utiliza transporte</label>
                    <Input
                      disabled
                      value={this.state.utilizaTransporte}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <br />
            <div className="grupo-info">
              <Row>
                <Col>
                  <h5>Filiação</h5>
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  <FormGroup>
                    <label>Nome da mãe</label>
                    <Input
                      disabled
                      value={this.state.aluno.nomeMae}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>Profissão da mãe</label>
                    <Input
                      disabled
                      value={this.state.aluno.profissaoMae}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  <FormGroup>
                    <label>Pai</label>
                    <Input
                      disabled
                      value={this.state.aluno.nomePai}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>Profissão do pai</label>
                    <Input
                      disabled
                      value={this.state.aluno.profissaoPai}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </TabPanel>
          {
            this.state.aluno.pne && <TabPanel>
              <Row>
                <Col>
                  <FormGroup>
                    <label>Tipo da necessidade especial</label>
                    <Input
                      disabled
                      value={this.state.aluno.tipoPne}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label>Laudo</label>
                    <TextareaAutosize
                      disabled
                      className={'autoheight'}
                      value={this.state.aluno.laudoPne} />
                  </FormGroup>
                </Col>
              </Row>
            </TabPanel>
          }
        </Tabs>
        <br />
        <Row>
          <Col className="text-center">
            <Button onClick={() => this.props.closeCallback()}>Fechar</Button>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    return (
      <Modal
        size="xl"
        style={{ maxWidth: '1600px', width: '80%' }}
        isOpen={this.props.isOpen}>
        <div className="modal-body">
          {
            !this.state.erro
              ? this.conteudoPagina()
              : <div align="center" style={{ margin: 50 }}>
                Erro ao buscar informações do aluno <br /><br />
                <Button onClick={() => this.props.closeCallback()}>Fechar</Button>
              </div>
          }
        </div>
      </Modal>
    );
  }
}

export default FichaAlunoModal;
