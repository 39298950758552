import React from 'react';
import { withRouter } from 'hoc/withRouter';

import env from 'conf/env.json';
// react plugin used to create charts
import { Bar, Doughnut } from 'react-chartjs-2';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Select from 'react-select';
import Loader from 'react-loader-spinner';

import LoaderModal from 'views/components/LoaderModal';
import Alert from 'views/components/Alert';

import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';
import DashboardService from 'services/DashboardService';

class DashboardSecretario extends React.Component {
  constructor(props) {
    super(props);

    this.loginService = new LoginService();
    this.entesService = new EntesService();
    this.dashboardService = new DashboardService();

    this.state = {
      showLoaderModal: false,
      showAlert: false,

      selectAnosLetivos: [],
      anoLetivoSelecionado: '',

      selectEscolas: [],
      escolaSelecionada: '',

      anoLetivo: null,

      error: null,
    };
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const dadosEnte = await this.entesService
        .carregarDadosEnte();

      const informacoesGerais = await this.dashboardService
        .carregarInformacoesGeraisSecretario();

      const selectAnosLetivos = [];
      informacoesGerais.anosLetivos.forEach((anoLetivo) => {
        selectAnosLetivos.push({
          value: anoLetivo.id,
          label: anoLetivo.ano,
        });
      });

      const anoLetivoSelecionado = selectAnosLetivos.length > 0
        ? selectAnosLetivos[selectAnosLetivos.length - 1] : '';

      const selectEscolas = [];
      informacoesGerais.escolas.forEach((escola) => {
        selectEscolas.push({
          value: escola.id,
          label: escola.nome,
        });
      });

      // const escolaSelecionada = anoLetivoSelecionado && selectEscolas.length > 0
      //  ? selectEscolas[0] : '';

      this.setState({
        dadosEnte,
        informacoesGerais,
        selectAnosLetivos,
        selectEscolas,
        anoLetivoSelecionado,
        // escolaSelecionada,
      });

      // if (anoLetivoSelecionado && escolaSelecionada) {
      //  await this.carregarInformacoesAnoLetivo(anoLetivoSelecionado.value,
      //    escolaSelecionada.value);
      // }
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarInformacoesAnoLetivo(idAnoLetivo, idEscola) {
    this.setState({
      showAlert: false,
    });

    try {
      const anoLetivo = await this.dashboardService
        .carregarInformacoesAnoLetivo(idAnoLetivo, idEscola);

      const scales = {
        xAxes: [{
          gridLines: {
            color: 'rgba(0, 0, 0, 0)',
          },
          ticks: {
            fontColor: env.CHART_LABEL_COLOR, // Set x-axis label color here
          }
        }],
        yAxes: [{
          ticks: {
            beginAtZero: true,
          },
        }],
      };

      const options = {
        events: ['mousemove'],
        onHover: (event) => {
          Object.assign(event.target.style, { cursor: 'pointer' });
        },
        hover: { mode: null },
        plugins: {
          datalabels: {
            color: env.CHART_LABEL_COLOR,
          }
        }
      };

      Object.assign(anoLetivo.graficoContagemGeral.options, options);
      Object.assign(anoLetivo.graficoTurmas.options, options);
      Object.assign(anoLetivo.graficoDiarios.options, options);
      Object.assign(anoLetivo.graficoSituacoesAlunos.options, options);
      Object.assign(anoLetivo.graficoMatriculasPorSerie.options, options);
      Object.assign(anoLetivo.graficoTurmasPorSerie.options, options);
      Object.assign(anoLetivo.graficoDistorcaoIdadeSerie.options, options);
      Object.assign(anoLetivo.graficoMatriculasPorSexo.options, options);
      Object.assign(anoLetivo.graficoMatriculasPorEtnia.options, options);
      Object.assign(anoLetivo.graficoTransporteEscolar.options, options);
      Object.assign(anoLetivo.graficoTaxaEvasao.options, options);

      Object.assign(anoLetivo.graficoTurmas.options.legend, { labels: { fontColor: env.CHART_LABEL_COLOR } });
      Object.assign(anoLetivo.graficoDiarios.options.legend, { labels: { fontColor: env.CHART_LABEL_COLOR } });

      if (this.state.dadosEnte.bnccNacional) {
        const charts = [
          anoLetivo.graficoMatriculasPorSerie,
          anoLetivo.graficoDistorcaoIdadeSerie,
          anoLetivo.graficoTurmasPorSerie,
          anoLetivo.graficoTaxaEvasao,
        ];
        charts.forEach((chart) => {
          chart.labels.shift()
          chart.datasets[0].data.shift();
          chart.datasets[0].backgroundColor.shift();
          chart.labels[0] = 'Creche 1';
          chart.labels[1] = 'Creche 2';
          chart.labels[2] = 'Creche 3';
        });
      }

      anoLetivo.graficoContagemGeral.options.scales = scales;
      anoLetivo.graficoSituacoesAlunos.options.scales = scales;
      anoLetivo.graficoMatriculasPorSerie.options.scales = scales;
      anoLetivo.graficoTurmasPorSerie.options.scales = scales;
      anoLetivo.graficoDistorcaoIdadeSerie.options.scales = scales;
      anoLetivo.graficoMatriculasPorSexo.options.scales = scales;
      anoLetivo.graficoMatriculasPorEtnia.options.scales = scales;
      anoLetivo.graficoTransporteEscolar.options.scales = scales;
      anoLetivo.graficoTaxaEvasao.options.scales = scales;

      this.setState({
        showLoaderModal: false,
        anoLetivo,
      });
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro carregar dados da escola no ano letivo',
      });
    }
  }

  conteudoPagina() {
    return this.state.informacoesGerais ? <>
      <div className="content">
        <Alert
          color={this.state.alertColor}
          isOpen={this.state.showAlert}
          toggle={() => { this.setState({ showAlert: false }); }}>
          {this.state.alertMsg}
        </Alert>
        <Breadcrumb>
          <BreadcrumbItem>Dashboard</BreadcrumbItem>
        </Breadcrumb>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="2" style={{ marginTop: 10 }}>
                    <Select
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      placeholder="Ano letivo..."
                      value={this.state.anoLetivoSelecionado}
                      onChange={(event) => {
                        this.setState({
                          anoLetivoSelecionado: event,
                          anoLetivo: null,
                          escolaSelecionada: '',
                        });
                      }}
                      options={this.state.selectAnosLetivos}
                    />
                  </Col>
                  {
                    this.state.anoLetivoSelecionado && <Col md="4" style={{ marginTop: 10 }}>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Escola..."
                        value={this.state.escolaSelecionada}
                        onChange={(event) => {
                          this.setState({
                            escolaSelecionada: event,
                            anoLetivo: null,
                          });
                          this.carregarInformacoesAnoLetivo(
                            this.state.anoLetivoSelecionado.value, event.value,
                          );
                        }}
                        options={this.state.selectEscolas}
                      />
                    </Col>
                  }
                </Row>
              </CardHeader>
              <CardBody>
                {
                  this.state.anoLetivo ? <>
                    <Row>
                      <Col md="4">
                        <p className="titulo-grafico">Contagem geral*</p>
                        <hr />
                        <Bar
                          onElementsClick={() => {
                            let path = `${this.props.layout}/dashboard/relatorios/contagemgeral`
                              + `/anoletivo/${this.state.anoLetivoSelecionado.value}`;

                            if (this.state.escolaSelecionada.value) {
                              path += `/escola/${this.state.escolaSelecionada.value}`;
                            }

                            this.props.history.push(path);
                          }}
                          data={this.state.anoLetivo.graficoContagemGeral}
                          options={this.state.anoLetivo.graficoContagemGeral.options}
                          width={500}
                          height={300}
                        />
                        <label>*Matrículas, turmas e diários de todos os cursos. </label>
                        <hr />
                      </Col>
                      <Col md="4">
                        <p className="titulo-grafico">Situação das turmas*</p>
                        <hr />
                        <Doughnut
                          onElementsClick={() => {
                            let path = `${this.props.layout}/dashboard/relatorios/situacaoturmas`
                              + `/anoletivo/${this.state.anoLetivoSelecionado.value}`;

                            if (this.state.escolaSelecionada.value) {
                              path += `/escola/${this.state.escolaSelecionada.value}`;
                            }

                            this.props.history.push(path);
                          }}
                          data={this.state.anoLetivo.graficoTurmas}
                          options={this.state.anoLetivo.graficoTurmas.options}
                          width={500}
                          height={300}
                        />
                        <label>*Turmas de todos os cursos. </label>
                        <hr />
                      </Col>
                      <Col md="4">
                        <p className="titulo-grafico">Situação dos diários*</p>
                        <hr />
                        <Doughnut
                          onElementsClick={() => {
                            let path = `${this.props.layout}/dashboard/relatorios/situacaodiarios`
                              + `/anoletivo/${this.state.anoLetivoSelecionado.value}`;

                            if (this.state.escolaSelecionada.value) {
                              path += `/escola/${this.state.escolaSelecionada.value}`;
                            }

                            this.props.history.push(path);
                          }}
                          data={this.state.anoLetivo.graficoDiarios}
                          options={this.state.anoLetivo.graficoDiarios.options}
                          width={500}
                          height={300}
                        />
                        <label>*Diários de todos os cursos. </label>
                        <hr />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 50 }}>
                      <Col md="6">
                        <p className="titulo-grafico">Informações sobre matrículas*</p>
                        <hr />
                        <Bar
                          onElementsClick={() => {
                            let path = `${this.props.layout}/dashboard/relatorios/informacoesmatriculas`
                              + `/anoletivo/${this.state.anoLetivoSelecionado.value}`;

                            if (this.state.escolaSelecionada.value) {
                              path += `/escola/${this.state.escolaSelecionada.value}`;
                            }

                            this.props.history.push(path);
                          }}
                          data={this.state.anoLetivo.graficoSituacoesAlunos}
                          options={this.state.anoLetivo.graficoSituacoesAlunos.options}
                          width={500}
                          height={300}
                        />
                        <label>*Matrículas dos cursos de ensino básico e EJA. </label>
                        <hr />
                      </Col>
                      <Col md="6">
                        <p className="titulo-grafico">Distorção idade série (%)*</p>
                        <hr />
                        <Bar
                          onElementsClick={() => {
                            let path = `${this.props.layout}/dashboard/relatorios/distorcaoidadeserie`
                              + `/anoletivo/${this.state.anoLetivoSelecionado.value}`;

                            if (this.state.escolaSelecionada.value) {
                              path += `/escola/${this.state.escolaSelecionada.value}`;
                            }

                            this.props.history.push(path);
                          }}
                          data={this.state.anoLetivo.graficoDistorcaoIdadeSerie}
                          options={this.state.anoLetivo.graficoDistorcaoIdadeSerie.options}
                          width={500}
                          height={300}
                        />
                        <label>*Desconsidera evadidos, transferidos e falecidos. </label>
                        <hr />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 50 }}>
                      <Col md="6">
                        <p className="titulo-grafico">Matrículas por série*</p>
                        <hr />
                        <Bar
                          onElementsClick={() => {
                            let path = `${this.props.layout}/dashboard/relatorios/matriculasserie`
                              + `/anoletivo/${this.state.anoLetivoSelecionado.value}`;

                            if (this.state.escolaSelecionada.value) {
                              path += `/escola/${this.state.escolaSelecionada.value}`;
                            }

                            this.props.history.push(path);
                          }}
                          data={this.state.anoLetivo.graficoMatriculasPorSerie}
                          options={this.state.anoLetivo.graficoMatriculasPorSerie.options}
                          width={500}
                          height={300}
                        />
                        <label>*Desconsidera evadidos, transferidos e falecidos. </label>
                        <hr />
                      </Col>
                      <Col md="6">
                        <p className="titulo-grafico">Turmas por série*</p>
                        <hr />
                        <Bar
                          onElementsClick={() => {
                            let path = `${this.props.layout}/dashboard/relatorios/turmasserie`
                              + `/anoletivo/${this.state.anoLetivoSelecionado.value}`;

                            if (this.state.escolaSelecionada.value) {
                              path += `/escola/${this.state.escolaSelecionada.value}`;
                            }

                            this.props.history.push(path);
                          }}
                          data={this.state.anoLetivo.graficoTurmasPorSerie}
                          options={this.state.anoLetivo.graficoTurmasPorSerie.options}
                          width={500}
                          height={300}
                        />
                        <label>*Quantidade de turmas na série. </label>
                        <hr />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <p className="titulo-grafico">Matrículas por sexo*</p>
                        <hr />
                        <Bar
                          onElementsClick={() => {
                            let path = `${this.props.layout}/dashboard/relatorios/matriculasporsexo`
                              + `/anoletivo/${this.state.anoLetivoSelecionado.value}`;

                            if (this.state.escolaSelecionada.value) {
                              path += `/escola/${this.state.escolaSelecionada.value}`;
                            }

                            this.props.history.push(path);
                          }}
                          data={this.state.anoLetivo.graficoMatriculasPorSexo}
                          options={this.state.anoLetivo.graficoMatriculasPorSexo.options}
                          width={500}
                          height={300}
                        />
                        <label>*Matrículas dos cursos de ensino básico e EJA. Desconsidera evadidos, transferidos e falecidos. </label>
                        <hr />
                      </Col>
                      <Col md="4">
                        <p className="titulo-grafico">Matrículas por etnia*</p>
                        <hr />
                        <Bar
                          onElementsClick={() => {
                            let path = `${this.props.layout}/dashboard/relatorios/matriculasporetnia`
                              + `/anoletivo/${this.state.anoLetivoSelecionado.value}`;

                            if (this.state.escolaSelecionada.value) {
                              path += `/escola/${this.state.escolaSelecionada.value}`;
                            }

                            this.props.history.push(path);
                          }}
                          data={this.state.anoLetivo.graficoMatriculasPorEtnia}
                          options={this.state.anoLetivo.graficoMatriculasPorEtnia.options}
                          width={500}
                          height={300}
                        />
                        <label>*Matrículas dos cursos de ensino básico e EJA. Desconsidera evadidos, transferidos e falecidos. </label>
                        <hr />
                      </Col>
                      <Col md="4">
                        <p className="titulo-grafico">Uso do transporte escolar por estudantes*</p>
                        <hr />
                        <Bar
                          onElementsClick={() => {
                            let path = `${this.props.layout}/dashboard/relatorios/usotransporteescolar`
                              + `/anoletivo/${this.state.anoLetivoSelecionado.value}`;

                            if (this.state.escolaSelecionada.value) {
                              path += `/escola/${this.state.escolaSelecionada.value}`;
                            }

                            this.props.history.push(path);
                          }}
                          data={this.state.anoLetivo.graficoTransporteEscolar}
                          options={this.state.anoLetivo.graficoTransporteEscolar.options}
                          width={500}
                          height={300}
                        />
                        <label>*Contagem geral de alunos que usam o transporte escolar. </label>
                        <hr />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: 50 }}>
                      <Col md={{ size: 6, offset: 3 }}>
                        <p className="titulo-grafico">Taxa de evasão (%)*</p>
                        <hr />
                        <Bar
                          onElementsClick={() => {
                            let path = `${this.props.layout}/dashboard/relatorios/taxaevasao`
                              + `/anoletivo/${this.state.anoLetivoSelecionado.value}`;

                            if (this.state.escolaSelecionada.value) {
                              path += `/escola/${this.state.escolaSelecionada.value}`;
                            }

                            this.props.history.push(path);
                          }}
                          data={this.state.anoLetivo.graficoTaxaEvasao}
                          options={this.state.anoLetivo.graficoTaxaEvasao.options}
                          width={500}
                          height={300}
                        />
                        <label>*Taxa de evasão no ensino básico. </label>
                        <hr />
                      </Col>
                    </Row>
                  </> : this.state.anoLetivoSelecionado && this.state.escolaSelecionada
                  && <div align="center" style={{ margin: 50 }}>
                    <Loader
                      type="Oval"
                      color="#34B5B8"
                      height="50"
                      width="50" />
                  </div>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text="Carregando..." />
      </div>
    );
  }
}

export default withRouter(DashboardSecretario);
