import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
	Row,
	Col,
	Breadcrumb,
	BreadcrumbItem,
	Card,
	CardHeader,
	CardTitle,
	CardBody,
	Button,
	Table,
	FormGroup,
	UncontrolledDropdown,
	DropdownMenu,
	DropdownToggle,
	DropdownItem,
} from 'reactstrap';

import uniqid from 'uniqid';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import MateriasService from 'services/MateriasService';
import HabilidadesCompetenciasEspecificasService from 'services/HabilidadesCompetenciasEspecificasService';
import ObjetivosAprendizagemCampoExperienciaService from 'services/ObjetivosAprendizagemCampoExperienciaService';
import ProfessorAuxiliarService from 'services/ProfessorAuxiliarService';

import Turno from 'assets/csv/turnos.json';
import Papel from 'assets/csv/papeis.json';
import SituacaoHabilidade from 'assets/csv/situacoes-habilidades-matricula';
import SituacaoHabilidadeMatricula from 'assets/csv/situacoes-habilidades-matricula';
import SituacaoObjetivo from 'assets/csv/situacoes-objetivos-aprendizagem-matricula';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';
import Serie from 'assets/csv/series2.json';
import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';
import SituacaoObjetivoAprendizagemMatricula from 'assets/csv/situacoes-objetivos-aprendizagem-matricula';

class SituacoesHabilidades extends React.Component {
	constructor(props) {
		super(props);

		this.situacoes = [
			SituacaoHabilidade.CONSTRUIDA,
			SituacaoHabilidade.EM_CONSTRUCAO,
			SituacaoHabilidade.NAO_CONSTRUIDA,
			SituacaoHabilidade.NAO_INFORMADA,
		];

		this.state = {
			carregandoHabilidades: true,
		}

		this.entesService = new EntesService();
		this.professorAuxiliarService = new ProfessorAuxiliarService();
		this.habilidadesCompetenciasEspecificasService = new HabilidadesCompetenciasEspecificasService();

		this.salvarHabilidadesTrabalhadas = this.salvarHabilidadesTrabalhadas.bind(this);
	}

	async componentDidUpdate(prevProps) {
		if (this.props.idMatricula !== prevProps.idMatricula || this.props.idMateria !== prevProps.idMateria) {
			await this.carregarSituacaoHabilidadeNaMatriculaEMateria(
				this.props.idMatricula,
				this.props.idMateria,
			);
		}
	}

	async componentDidMount() {
		const dadosEnte = await this.entesService.carregarDadosEnte();

		const professoreAuxiliar = await this.professorAuxiliarService
			.carregarMeuProfessorAuxiliar();

		await this.carregarSituacaoHabilidadeNaMatriculaEMateria(
			this.props.idMatricula,
			this.props.idMateria,
		);

		this.setState({
			auxiliarBloqueado: this.props.role === Papel.PROFESSOR.value && professoreAuxiliar.auxiliar && dadosEnte.bloquearAlteracaoDiarioProfessorAuxiliar,
		});
	}

	async carregarSituacaoHabilidadeNaMatriculaEMateria(idMatricula, idMateria) {
		if (!idMatricula || !idMateria) return;

		this.setState({ carregandoHabilidades: true });

		const habilidades = await this.habilidadesCompetenciasEspecificasService
			.carregarHabilidadesPorMateria(idMateria);

		const fn = (habilidade) => new Promise((resolve) => resolve(
			this.habilidadesCompetenciasEspecificasService
				.carregarSituacaoHabilidadeNaMatriculaEMateria(habilidade.id,
					idMatricula, idMateria),
		));

		const actions = habilidades.map(fn);

		const situacoesHabilidades = await Promise.all(actions);

		this.setState({
			situacoesHabilidades,
			carregandoHabilidades: false,
		});
	}

	async salvarHabilidadesTrabalhadas() {
		this.props.callbackMessage({
			showAlert: false,
			showLoaderModal: true,
		});

		try {
			await this.habilidadesCompetenciasEspecificasService.salvarHabilidadesAdquiridasMatriculaMateria(
				this.props.idMatricula,
				this.props.idMateria,
				this.state.situacoesHabilidades,
			);

			await this.carregarSituacaoHabilidadeNaMatriculaEMateria(
				this.props.idMatricula,
				this.props.idMateria,
			);

			this.props.callbackMessage({
				showAlert: true,
				alertColor: 'success',
				alertMsg: 'Habilidades trabalhadas salvas com sucesso',
				showLoaderModal: false,
			});
		} catch (msg) {

			this.props.callbackMessage({
				showAlert: true,
				alertColor: 'danger',
				alertMsg: msg || 'Erro ao salvar habilidades trabalhadas',
				showLoaderModal: false,
			});
		}
	}

	render() {
		return (
			!this.props.idMatricula
				? <div align="center" style={{ padding: 30 }}>
					Nenhum aluno matriculado
				</div>
				: this.state.carregandoHabilidades
					? <div align="center" style={{ padding: 30 }}>
						<Loader
							type="Oval"
							color="#34B5B8"
							height="50"
							width="50" />
					</div>
					: <>
						<Table>
							<thead className="text-primary">
								<tr>
									<th style={{ width: '80%' }}>Descrição</th>
									<th className="text-center" style={{ width: '20%' }}>Situação</th>
								</tr>
							</thead>
							<tbody>
								{
									this.state.situacoesHabilidades.map((situacaoHabilidade) => <tr
										key={uniqid()}>
										<td><div className="preserve-format">{situacaoHabilidade.habilidade.descricao}</div></td>
										<td className="text-center">
											<UncontrolledDropdown>
												<DropdownToggle
													aria-haspopup={true}
													color={this.situacoes[situacaoHabilidade.situacao].color}
													data-toggle="collapse"
													id="acoesHabilidadesDropdownMenuLink"
													onClick={(evt) => evt.stopPropagation()}
													nav>
													<Button color={this.situacoes[situacaoHabilidade.situacao].color}>
														{this.situacoes[situacaoHabilidade.situacao].label} <b className="caret" />
													</Button>
												</DropdownToggle>
												<DropdownMenu aria-labelledby="acoesHabilidadesDropdownMenuLink" right>
													<DropdownItem
														onClick={() => {
															if (this.props.role !== Papel.PROFESSOR.value) return;
															Object.assign(situacaoHabilidade, {
																situacao: SituacaoObjetivoAprendizagemMatricula.NAO_INFORMADA.value,
															});
															this.forceUpdate();
														}}>
														{SituacaoObjetivoAprendizagemMatricula.NAO_INFORMADA.label}
													</DropdownItem>
													<DropdownItem
														onClick={() => {
															if (this.props.role !== Papel.PROFESSOR.value) return;
															Object.assign(situacaoHabilidade, {
																situacao: SituacaoObjetivoAprendizagemMatricula.CONSTRUIDA.value,
															});
															this.forceUpdate();
														}}>
														{SituacaoObjetivoAprendizagemMatricula.CONSTRUIDA.label}
													</DropdownItem>
													<DropdownItem
														onClick={() => {
															if (this.props.role !== Papel.PROFESSOR.value) return;
															Object.assign(situacaoHabilidade, {
																situacao: SituacaoObjetivoAprendizagemMatricula.EM_CONSTRUCAO.value,
															});
															this.forceUpdate();
														}}>
														{SituacaoObjetivoAprendizagemMatricula.EM_CONSTRUCAO.label}
													</DropdownItem>
													<DropdownItem
														onClick={() => {
															if (this.props.role !== Papel.PROFESSOR.value) return;
															Object.assign(situacaoHabilidade, {
																situacao: SituacaoObjetivoAprendizagemMatricula.NAO_CONSTRUIDA.value,
															});
															this.forceUpdate();
														}}>
														{SituacaoObjetivoAprendizagemMatricula.NAO_CONSTRUIDA.label}
													</DropdownItem>
												</DropdownMenu>
											</UncontrolledDropdown>
										</td>
									</tr>)
								}
							</tbody>
						</Table>
						<Row>
							<Col className="text-left">
								<Button
									color="primary"
									onClick={() => this.props.callbackVoltar()} >
									Voltar
								</Button>
							</Col>
							<Col className="text-right">
								{
									this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado && <Button
										color="primary"
										disabled={this.state.showLoaderModal}
										onClick={this.salvarHabilidadesTrabalhadas} >
										Salvar objetivos de aprendizagem do aluno
									</Button>
								}
							</Col>
						</Row>
					</>
		);
	}
}

class SituacoesHabilidadesBimestres extends React.Component {
	constructor(props) {
		super(props);

		this.situacoes = [
			SituacaoHabilidade.CONSTRUIDA,
			SituacaoHabilidade.EM_CONSTRUCAO,
			SituacaoHabilidade.NAO_CONSTRUIDA,
			SituacaoHabilidade.NAO_INFORMADA,
		];

		this.state = {
			carregandoHabilidades: true,
		}

		this.salvarHabilidadesAdquiridas = this.salvarHabilidadesAdquiridas.bind(this);

		this.entesService = new EntesService();
		this.professorAuxiliarService = new ProfessorAuxiliarService();
		this.habilidadesCompetenciasEspecificasService = new HabilidadesCompetenciasEspecificasService();
	}

	async componentDidUpdate(prevProps) {
		if (this.props.idMatricula !== prevProps.idMatricula || this.props.idMateria !== prevProps.idMateria) {
			await this.carregarSituacaoHabilidade(
				this.props.idMatricula,
				this.props.idMateria,
			);
		}
	}

	async componentDidMount() {
		const dadosEnte = await this.entesService.carregarDadosEnte();

		const professoreAuxiliar = await this.professorAuxiliarService.carregarMeuProfessorAuxiliar();

		await this.carregarSituacaoHabilidade(
			this.props.idMatricula,
			this.props.idMateria,
		);

		this.setState({
			auxiliarBloqueado: this.props.role === Papel.PROFESSOR.value && professoreAuxiliar.auxiliar && dadosEnte.bloquearAlteracaoDiarioProfessorAuxiliar,
		});
	}

	async carregarSituacaoHabilidade(idMatricula, idMateria) {
		if (!idMateria || !idMateria) return;

		try {
			this.setState({ carregandoHabilidades: true });

			const habilidades = await this.habilidadesCompetenciasEspecificasService
				.carregarHabilidadesPorMateria(idMateria);

			const situacoesBimestres = [];
			for (let bimestre = 1; bimestre <= 4; ++bimestre) {
				const fn = (habilidade) => new Promise((resolve) => resolve(
					this.habilidadesCompetenciasEspecificasService
						.carregarSituacaoHabilidadeNaMatriculaMateriaEBimestre(habilidade.id, idMatricula, idMateria, bimestre),
				));
				const actions = habilidades.map(fn);
				const situacoesHabilidades = await Promise.all(actions);
				situacoesBimestres.push(situacoesHabilidades);
			}

			this.setState({
				habilidades,
				situacoesBimestres,
				carregandoHabilidades: false,
			});

		} catch (msg) {
			this.props.callbackMessage({
				showAlert: true,
				alertColor: 'danger',
				alertMsg: msg || 'Erro ao carregar situações',
			});
		}
	}

	async salvarHabilidadesAdquiridas() {
		this.props.callbackMessage({
			showAlert: false,
			showLoaderModal: true,
		});

		try {
			await this.habilidadesCompetenciasEspecificasService.salvarHabilidadesAdquiridasMatriculaMateriaBimestres(
				this.props.idMatricula,
				this.props.idMateria,
				this.state.situacoesBimestres,
			);

			this.props.callbackMessage({
				showLoaderModal: false,
				showAlert: true,
				alertColor: 'success',
				alertMsg: 'Objetivos de aprendizagem do aluno salvos com sucesso',
			});
		} catch (msg) {
			this.props.callbackMessage({
				showLoaderModal: false,
				showAlert: true,
				alertColor: 'danger',
				alertMsg: 'Erro ao salvar objetivos de aprendizagem do aluno',
			});
		}
	}

	render() {
		return (
			!this.props.idMatricula
				? <div align="center" style={{ padding: 30 }}>
					Nenhum aluno matriculado
				</div>
				: this.state.carregandoHabilidades
					? <div align="center" style={{ padding: 30 }}>
						<Loader
							type="Oval"
							color="#34B5B8"
							height="50"
							width="50" />
					</div>
					: <>
						<Row>
							<Col>
								<Table>
									<thead className="text-primary">
										<tr>
											<th rowSpan="2" style={{ width: '40%' }}>Descrição</th>
											<th colSpan="4" className="text-center" style={{ width: '60%' }}>Situação</th>
										</tr>
										<tr>
											<th className="text-center">1º Bimestre</th>
											<th className="text-center">2º Bimestre</th>
											<th className="text-center">3º Bimestre</th>
											<th className="text-center">4º Bimestre</th>
										</tr>
									</thead>
									<tbody>
										{
											this.state.habilidades.map((habilidade, idxh) => <tr
												key={uniqid()}>
												<td><div className="preserve-format">{habilidade.descricao}</div></td>
												{
													[0, 1, 2, 3].map((bimestre) => <td key={uniqid()} className="text-center">
														<UncontrolledDropdown>
															<DropdownToggle
																aria-haspopup={true}
																color={this.situacoes[this.state.situacoesBimestres[bimestre][idxh].situacao].color}
																data-toggle="collapse"
																id="acoesHabilidadesDropdownMenuLink"
																onClick={(evt) => evt.stopPropagation()}
																nav>
																<Button color={this.situacoes[this.state.situacoesBimestres[bimestre][idxh].situacao].color}>
																	{this.situacoes[this.state.situacoesBimestres[bimestre][idxh].situacao].label} <b className="caret" />
																</Button>
															</DropdownToggle>
															<DropdownMenu aria-labelledby="acoesHabilidadesDropdownMenuLink" right>
																<DropdownItem
																	onClick={() => {
																		if (this.props.role !== Papel.PROFESSOR.value) return;
																		Object.assign(this.state.situacoesBimestres[bimestre][idxh], {
																			...this.state.situacoesBimestres[bimestre][idxh],
																			situacao: SituacaoHabilidadeMatricula.NAO_INFORMADA.value
																		});
																		this.forceUpdate();
																	}}>
																	{SituacaoHabilidadeMatricula.NAO_INFORMADA.label}
																</DropdownItem>
																<DropdownItem
																	onClick={() => {
																		if (this.props.role !== Papel.PROFESSOR.value) return;
																		Object.assign(this.state.situacoesBimestres[bimestre][idxh], {
																			...this.state.situacoesBimestres[bimestre][idxh],
																			situacao: SituacaoHabilidadeMatricula.CONSTRUIDA.value
																		});
																		this.forceUpdate();
																	}}>
																	{SituacaoHabilidadeMatricula.CONSTRUIDA.label}
																</DropdownItem>
																<DropdownItem
																	onClick={() => {
																		if (this.props.role !== Papel.PROFESSOR.value) return;
																		Object.assign(this.state.situacoesBimestres[bimestre][idxh], {
																			...this.state.situacoesBimestres[bimestre][idxh],
																			situacao: SituacaoHabilidadeMatricula.EM_CONSTRUCAO.value
																		});
																		this.forceUpdate();
																	}}>
																	{SituacaoHabilidadeMatricula.EM_CONSTRUCAO.label}
																</DropdownItem>
																<DropdownItem
																	onClick={() => {
																		if (this.props.role !== Papel.PROFESSOR.value) return;
																		Object.assign(this.state.situacoesBimestres[bimestre][idxh], {
																			...this.state.situacoesBimestres[bimestre][idxh],
																			situacao: SituacaoHabilidadeMatricula.NAO_CONSTRUIDA.value
																		});
																		this.forceUpdate();
																	}}>
																	{SituacaoHabilidadeMatricula.NAO_CONSTRUIDA.label}
																</DropdownItem>
															</DropdownMenu>
														</UncontrolledDropdown>
													</td>)
												}
											</tr>)
										}
									</tbody>
								</Table>
							</Col>
						</Row>
						<Row>
							<Col className="text-left">
								<Button
									color="primary"
									onClick={() => this.props.callbackVoltar()} >
									Voltar
								</Button>
							</Col>
							<Col className="text-right">
								{
									this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado && <Button
										color="primary"
										disabled={this.state.showLoaderModal}
										onClick={this.salvarHabilidadesAdquiridas} >
										Salvar objetivos de aprendizagem do aluno
									</Button>
								}
							</Col>
						</Row>
					</>
		);
	}
}

class SituacoesObjetivos extends React.Component {
	constructor(props) {
		super(props);

		this.situacoes = [
			SituacaoObjetivo.CONSTRUIDA,
			SituacaoObjetivo.EM_CONSTRUCAO,
			SituacaoObjetivo.NAO_CONSTRUIDA,
			SituacaoObjetivo.NAO_INFORMADA,
		];

		this.state = {
			carregandoObjetivos: true,
			serie: props.serie,
		}

		this.salvarObjetivosTrabalhados = this.salvarObjetivosTrabalhados.bind(this);

		this.entesService = new EntesService();
		this.professorAuxiliarService = new ProfessorAuxiliarService();
		this.objetivosAprendizagemCampoExperienciaService = new ObjetivosAprendizagemCampoExperienciaService();
	}

	async componentDidUpdate(prevProps) {
		if (this.props.idMatricula !== prevProps.idMatricula ||
			this.props.idMateria !== prevProps.idMateria ||
			this.props.serie !== prevProps.serie
		) {
			await this.carregarSituacaoObjetivoNaMatriculaEMateria(
				this.props.idMatricula,
				this.props.idMateria,
				this.props.serie,
			);
		}

	}

	async componentDidMount() {
		const dadosEnte = await this.entesService.carregarDadosEnte();

		const professoreAuxiliar = await this.professorAuxiliarService
			.carregarMeuProfessorAuxiliar();

		await this.carregarSituacaoObjetivoNaMatriculaEMateria(
			this.props.idMatricula,
			this.props.idMateria,
			this.props.serie,
		);

		this.setState({
			auxiliarBloqueado: this.props.role === Papel.PROFESSOR.value && professoreAuxiliar.auxiliar && dadosEnte.bloquearAlteracaoDiarioProfessorAuxiliar,
		});
	}

	async carregarSituacaoObjetivoNaMatriculaEMateria(idMatricula, idMateria, serie) {
		if (!idMatricula || !idMateria) return;
		
		this.setState({ carregandoObjetivos: true });

		const objetivosAprendizagem = await this.objetivosAprendizagemCampoExperienciaService
			.carregarObjetivosAprendizagemPorMateria(idMateria);

		const fn1 = (objetivoAprendizagem) => new Promise((resolve) => resolve(
			this.objetivosAprendizagemCampoExperienciaService.carregarObjetivosEspecificosDoObjetivoAprendizagem(objetivoAprendizagem.id),
		));
		const actions1 = objetivosAprendizagem.map(fn1);
		const objetivosEspecificos = await Promise.all(actions1);

		objetivosAprendizagem.forEach((objetivoAprendizagem, idx) => Object.assign(objetivoAprendizagem, {
			objetivosEspecificos: objetivosEspecificos[idx]
		}));

		const fn = (objetivo) => new Promise((resolve) => resolve(
			this.objetivosAprendizagemCampoExperienciaService
				.carregarSituacaoObjetivoAprendizagemMatriculaMateria(objetivo.id, idMatricula, idMateria),
		));

		for (const objetivoAprendizagem of objetivosAprendizagem) {
			const actions = objetivoAprendizagem.objetivosEspecificos.map(fn);

			const situacoesObjetivos = await Promise.all(actions);
			objetivoAprendizagem.objetivosEspecificos.forEach((objetivoEspecifico, idx) => {
				Object.assign(
					objetivoEspecifico, { situacaoMatricula: situacoesObjetivos[idx] },
				);
			});
		}

		let anos1;
		let anos2;
		if (serie === Serie.BERCARIO_1.value || serie === Serie.BERCARIO_2.value) {
			anos1 = '0 anos';
			anos2 = '1 anos';
		} else if (serie === Serie.CRECHE_1.value || serie === Serie.CRECHE_2.value) {
			anos1 = '2 anos';
			anos2 = '3 anos';
		} else {
			anos1 = '4 anos';
			anos2 = '5 anos';
		}

		this.setState({
			objetivosAprendizagem,
			carregandoObjetivos: false,
			anos1,
			anos2,
		});
	}

	async salvarObjetivosTrabalhados() {
		this.props.callbackMessage({
			showLoaderModal: true,
			showAlert: false,
		});

		try {
			await this.objetivosAprendizagemCampoExperienciaService.salvarObjetivosAdquiridosMatriculaMateria(
				this.props.idMatricula,
				this.props.idMateria,
				this.state.objetivosAprendizagem,
			);

			await this.carregarSituacaoObjetivoNaMatriculaEMateria(
				this.props.idMatricula,
				this.props.idMateria,
				this.props.serie,
			);

			this.props.callbackMessage({
				showLoaderModal: false,
				showAlert: true,
				alertColor: 'success',
				alertMsg: 'Objetivos de aprendizagem salvos com sucesso',
			});
		} catch (msg) {
			this.props.callbackMessage({
				showLoaderModal: false,
				showAlert: true,
				alertColor: 'danger',
				alertMsg: msg || 'Erro ao salvar objetivos de aprendizagem',
			});
		}
	}

	render() {
		return (
			!this.props.idMatricula
				? <div align="center" style={{ padding: 30 }}>
					Nenhum aluno matriculado
				</div>
				: this.state.carregandoObjetivos
					? <div align="center" style={{ padding: 30 }}>
						<Loader
							type="Oval"
							color="#34B5B8"
							height="50"
							width="50" />
					</div>
					: <>
						<Table>
							<thead className="text-primary">
								<tr>
									<th rowSpan="2" className="text-center" style={{ width: '20%' }}>Objetivo de aprendizagem e desenvolvimento</th>
									<th colSpan="2" className="text-center" style={{ width: '60%' }}>Objetivo de específico</th>
									<th rowSpan="2" className="text-center" style={{ width: '20%' }}>Situação</th>
								</tr>
								<tr>
									<th className="text-center">{this.state.anos1}</th>
									<th className="text-center">{this.state.anos2}</th>
								</tr>
							</thead>
							<tbody>
								{
									this.state.objetivosAprendizagem.map((objetivoAprendizagem, index1) => <React.Fragment key={index1}>
										{
											objetivoAprendizagem.objetivosEspecificos.map((objetivoEspecifico, index2) => <tr key={index2}>
												{
													index2 === 0 && <td style={{ width: '20%' }} rowSpan={objetivoAprendizagem.objetivosEspecificos.length}>
														<div className="preserve-format">{objetivoAprendizagem.descricao}</div>
													</td>
												}
												<td style={{ width: '30%' }}>
													<div className="preserve-format">{objetivoEspecifico.descricao}</div>
												</td>
												<td style={{ width: '30%' }}>
													<div className="preserve-format">{objetivoEspecifico.descricao2}</div>
												</td>
												<td style={{ width: '20%' }} className="text-center">
													<UncontrolledDropdown>
														<DropdownToggle
															aria-haspopup={true}
															color={this.situacoes[objetivoEspecifico.situacaoMatricula.situacao].color}
															data-toggle="collapse"
															id="acoesHabilidadesDropdownMenuLink"
															onClick={(evt) => evt.stopPropagation()}
															nav>
															<Button color={this.situacoes[objetivoEspecifico.situacaoMatricula.situacao].color}>
																{this.situacoes[objetivoEspecifico.situacaoMatricula.situacao].label} <b className="caret" />
															</Button>
														</DropdownToggle>
														<DropdownMenu aria-labelledby="acoesHabilidadesDropdownMenuLink" right>
															<DropdownItem
																onClick={() => {
																	if (this.props.role !== Papel.PROFESSOR.value) return;
																	Object.assign(objetivoEspecifico.situacaoMatricula, {
																		situacao: SituacaoObjetivoAprendizagemMatricula.NAO_INFORMADA.value,
																	});
																	this.forceUpdate();
																}}>
																{SituacaoObjetivoAprendizagemMatricula.NAO_INFORMADA.label}
															</DropdownItem>
															<DropdownItem
																onClick={() => {
																	if (this.props.role !== Papel.PROFESSOR.value) return;
																	Object.assign(objetivoEspecifico.situacaoMatricula, {
																		situacao: SituacaoObjetivoAprendizagemMatricula.CONSTRUIDA.value,
																	});
																	this.forceUpdate();
																}}>
																{SituacaoObjetivoAprendizagemMatricula.CONSTRUIDA.label}
															</DropdownItem>
															<DropdownItem
																onClick={() => {
																	if (this.props.role !== Papel.PROFESSOR.value) return;
																	Object.assign(objetivoEspecifico.situacaoMatricula, {
																		situacao: SituacaoObjetivoAprendizagemMatricula.EM_CONSTRUCAO.value,
																	});
																	this.forceUpdate();
																}}>
																{SituacaoObjetivoAprendizagemMatricula.EM_CONSTRUCAO.label}
															</DropdownItem>
															<DropdownItem
																onClick={() => {
																	if (this.props.role !== Papel.PROFESSOR.value) return;
																	Object.assign(objetivoEspecifico.situacaoMatricula, {
																		situacao: SituacaoObjetivoAprendizagemMatricula.NAO_CONSTRUIDA.value,
																	});
																	this.forceUpdate();
																}}>
																{SituacaoObjetivoAprendizagemMatricula.NAO_CONSTRUIDA.label}
															</DropdownItem>
														</DropdownMenu>
													</UncontrolledDropdown>
												</td>
											</tr>)
										}
									</React.Fragment>)
								}
							</tbody>
						</Table>
						<Row>
							<Col className="text-left">
								<Button
									color="primary"
									onClick={() => this.props.callbackVoltar()} >
									Voltar
								</Button>
							</Col>
							<Col className="text-right">
								{
									this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado && <Button
										color="primary"
										disabled={this.state.showLoaderModal}
										onClick={this.salvarObjetivosTrabalhados} >
										Salvar Objetivos trabalhados
									</Button>
								}
							</Col>
						</Row>
					</>
		);
	}
}

class ObjetivosAprendizagemOuHabilidadesAdquiridasAlunoMultiseriado extends React.Component {
	constructor(props) {
		super(props);

		this.turnos = [
			Turno.MATUTINO,
			Turno.VESPERTINO,
			Turno.NOTURNO,
			Turno.INTEGRAL,
		];

		this.situacoesMatricula = [
			SituacaoMatricula.MATRICULADO,
			SituacaoMatricula.TRANSFERIDO,
			SituacaoMatricula.DESISTENTE,
			SituacaoMatricula.APROVADO,
			SituacaoMatricula.APROVADO_COM_DEPENDENCIA,
			SituacaoMatricula.REPROVADO_POR_NOTA,
			SituacaoMatricula.REPROVADO_POR_FREQUENCIA,
			SituacaoMatricula.REPROVADO_POR_FREQUENCIA_E_NOTA,
			SituacaoMatricula.NAO_MATRICULADO,
			SituacaoMatricula.FALECIDO,
		];

		this.state = {
			showAlert: false,
			showLoaderModal: false,

			error: null,
		};

		this.loginService = new LoginService();
		this.entesService = new EntesService();
		this.anosLetivosService = new AnosLetivosService();
		this.escolasService = new EscolasService();
		this.turmasService = new TurmasService();
		this.diariosService = new DiariosService();
		this.materiasService = new MateriasService();
	}

	async componentDidMount() {
		await this.loginService.verificarPapel(this.props.role);
		try {
			const anoLetivo = await this.anosLetivosService
				.carregarAnoLetivo(this.props.match.params.idAnoLetivo);

			const dadosEnte = await this.entesService
				.carregarDadosEnte();

			const escola = await this.escolasService
				.carregarEscola(this.props.match.params.idEscola);

			const turma = await this.turmasService
				.carregarTurmaMultiseriado(this.props.match.params.idTurma);

			const matricula = await this.turmasService
				.carregarMatriculaPorId(this.props.match.params.idMatricula);

			const diarios = await this.diariosService
				.carregarDiariosDaTurmaMultiseriado(this.props.match.params.idTurma);

			diarios.sort((d1, d2) => d1.serie - d2.serie);

			const selectDiarios = [];
			diarios.forEach((diario) => {
				selectDiarios.push({
					label: diario.curso,
					value: diario.id,
					curso: diario.curso,
					serie: diario.serie,
					cicloEnsino: diario.cicloEnsino,
				});
			});

			const diario = diarios.find((d) => d.idTurma === matricula.idTurma)

			const diarioSelecionado = {
				label: diario.curso,
				value: diario.id,
				curso: diario.curso,
				serie: diario.serie,
				cicloEnsino: diario.cicloEnsino,
			};

			await this.carregarMatriculasEMaterias(diarioSelecionado, matricula);

			this.setState({
				dadosEnte,
				anoLetivo,
				escola,
				turma,
				selectDiarios,
			});
		} catch (e) {
			this.setState({ erro: true });
		}
	}

	async carregarMatriculasEMaterias(diarioSelecionado, matricula) {
		const materias = await this.diariosService
			.carregarMateriasDoProfessor(diarioSelecionado.value);

		const matriculas = await this.diariosService
			.carregarMatriculas(diarioSelecionado.value);

		const selectMatriculas = [];
		matriculas.forEach((matricula) => {
			selectMatriculas.push({
				label: `${matricula.usuario.nome} ${matricula.situacao !== SituacaoMatricula.MATRICULADO.value ? `(${this.situacoesMatricula[matricula.situacao].label})` : ''}`,
				value: matricula.id,
			});
		});

		const selectMaterias = [];
		materias.forEach((materia) => {
			selectMaterias.push({
				label: materia.nome,
				value: materia.id,
			});
		});

		let materiaSelecionada = {
			label: materias[0].nome,
			value: materias[0].id,
		};

		let matriculaSelecionada;
		try {
			matriculaSelecionada = matricula
				? {
					label: `${matricula.usuario.nome} ${matricula.situacao !== SituacaoMatricula.MATRICULADO.value ? `(${this.situacoesMatricula[matricula.situacao].label})` : ''}`,
					value: matricula.id,
				}
				: {
					label: `${matriculas[0].usuario.nome} ${matriculas[0].situacao !== SituacaoMatricula.MATRICULADO.value ? `(${this.situacoesMatricula[matriculas[0].situacao].label})` : ''}`,
					value: matriculas[0].id,
				};
		} catch (e) {
			matriculaSelecionada = '';
			materiaSelecionada = '';
		}

		this.setState({
			diarioSelecionado,
			selectMatriculas,
			matriculaSelecionada,
			selectMaterias,
			materiaSelecionada,
		});

	}

	voltar() {
		return `${this.props.layout}`
			+ `/anosletivos/${this.props.match.params.idAnoLetivo}`
			+ `/escola/${this.state.escola.id}`
			+ `/turma/multiseriado/${this.state.turma.id}`
			+ `/diario`;
	}

	conteudoPagina() {
		return this.state.escola ? <>
			<Row>
				<Col md="12">
					<Alert
						color={this.state.alertColor}
						isOpen={this.state.showAlert}
						toggle={() => { this.setState({ showAlert: false }); }}>
						{this.state.alertMsg}
					</Alert>
					<Breadcrumb>
						<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
						<BreadcrumbItem><Link to={this.voltar()}>Diário único</Link></BreadcrumbItem>
						<BreadcrumbItem active>Objetivos de aprendizagem de {this.state.materiaSelecionada.label}</BreadcrumbItem>
					</Breadcrumb>
				</Col>
			</Row>
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<CardTitle tag="h4">
								Objetivos de aprendizagem de {this.state.materiaSelecionada.label}
								&nbsp;de {this.state.matriculaSelecionada.label} no {this.state.diarioSelecionado.curso}
							</CardTitle>
						</CardHeader>
						<CardBody>
							<Row>
								<Col md="3">
									<FormGroup className={'has-label has-danger'}>
										<label>Curso</label>
										<Select
											noOptionsMessage={() => 'Nenhuma entrada'}
											className={'react-select primary'}
											classNamePrefix="react-select"
											value={this.state.diarioSelecionado}
											onChange={async (event) => {
												await this.carregarMatriculasEMaterias(event, null);
											}}
											options={this.state.selectDiarios}
										/>
									</FormGroup>
								</Col>
								<Col md="3">
									<FormGroup className={'has-label has-danger'}>
										<label>Aluno</label>
										<Select
											isDisabled={!this.state.matriculaSelecionada}
											placeholder="Aluno..."
											noOptionsMessage={() => 'Nenhuma entrada'}
											className={'react-select primary'}
											classNamePrefix="react-select"
											value={this.state.matriculaSelecionada}
											onChange={async (event) => {
												this.setState({ matriculaSelecionada: event })
											}}
											options={this.state.selectMatriculas}
										/>
									</FormGroup>
								</Col>
								<Col md="3">
									<FormGroup className={'has-label has-danger'}>
										<label>Campos de experiências</label>
										<Select
											isDisabled={!this.state.matriculaSelecionada}
											placeholder="Campos de experiência..."
											noOptionsMessage={() => 'Nenhuma entrada'}
											className={'react-select primary'}
											classNamePrefix="react-select"
											value={this.state.materiaSelecionada}
											onChange={async (event) => {
												this.setState({ materiaSelecionada: event })
											}}
											options={this.state.selectMaterias}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									{
										this.state.diarioSelecionado.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value &&
											!this.state.dadosEnte.bnccNacional ? <SituacoesObjetivos
											idMatricula={this.state.matriculaSelecionada.value}
											idMateria={this.state.materiaSelecionada.value}
											role={this.props.role}
											serie={this.state.diarioSelecionado.serie}
											callbackMessage={(msg) => this.setState(msg)}
											callbackVoltar={() => this.props.history.push(this.voltar())}
										/> : this.props.dadosUsuario.uf !== 'PB' && !this.state.dadosEnte.habilidadesBimestres ? <SituacoesHabilidades
											idMatricula={this.state.matriculaSelecionada.value}
											idMateria={this.state.materiaSelecionada.value}
											role={this.props.role}
											callbackMessage={(msg) => this.setState(msg)}
											callbackVoltar={() => this.props.history.push(this.voltar())}
										/> : <SituacoesHabilidadesBimestres
											idMatricula={this.state.matriculaSelecionada.value}
											idMateria={this.state.materiaSelecionada.value}
											role={this.props.role}
											callbackMessage={(msg) => this.setState(msg)}
											callbackVoltar={() => this.props.history.push(this.voltar())}
										/>
									}
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</> : <Card>
			<div align="center" style={{ margin: 50 }}>
				<Loader
					type="Oval"
					color="#34B5B8"
					height="50"
					width="50" />
			</div>
		</Card>;
	}

	render() {
		return (
			<div className='content'>
				{!this.state.erro
					? this.conteudoPagina()
					: <Card>
						<div align="center" style={{ margin: 50 }}>
							Erro ao buscar informações da página
						</div>
					</Card>
				}
				<LoaderModal
					isOpen={this.state.showLoaderModal}
					text="Salvando habilidades trabalhadas..." />
			</div>
		);
	}
}

export default withRouter(ObjetivosAprendizagemOuHabilidadesAdquiridasAlunoMultiseriado);
