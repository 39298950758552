import React from 'react';

import {
  Col,
  Row
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import AwsService from 'services/AwsService';
import TurmasService from 'services/TurmasService';

class MuraisFotosAluno extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      erro: false,
      loading: true,

      muraisFotos: [],
    };

    this.turmasService = new TurmasService();
    this.awsService = new AwsService();

  }

  async componentDidMount() {
    try {
      await this.carregarMuraisFotos();
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarMuraisFotos() {
    this.setState({
      loading: true,
    });

    const _muraisFotos = await this.turmasService.carregarMuraisFotos(this.props.matricula.idTurma);

    const urlPromises = _muraisFotos.map((muralFotos) => new Promise(async (resolve) => {
      const url = await this.awsService.getPresignedUrl(muralFotos.url);
      resolve(url);
    }));

    const urls = await Promise.all(urlPromises);

    const muraisFotos = _muraisFotos.map((muralFotos, idx) => ({
      ...muralFotos,
      menuAberto: false,
      src: urls[idx],
    }));

    this.setState({
      muraisFotos,
      loading: false,
    });
  }

  abrirMuralFotos(muralFotos) {
    this.props.history.push(`${this.props.layout}/turmas/${this.props.matricula.id}/muraisfotos/${muralFotos.id}`);
  }

  conteudoPagina() {
    return this.state.loading ? <div align="center" style={{ padding: 30 }}>
      <Loader
        type="Oval"
        color="#34B5B8"
        height="50"
        width="50" />
    </div> : <>
      <Row>
        {
          this.state.muraisFotos.map((muralFotos, idx) => <Col md="2" className="mt-4" key={idx} onClick={() => this.abrirMuralFotos(muralFotos)}>
            <div className="album-container">
              <img src={muralFotos.src} alt="Imagem Recortada" />
              <div className="album-label album-nome">{muralFotos.nome}</div>
              <div className="album-label album-quantidade-fotos">
                {muralFotos.quantidadeFotos} {muralFotos.quantidadeFotos > 1 ? "Fotos" : "Foto"}
              </div>
              <div className="album-label album-data">{muralFotos.data}</div>
            </div>
          </Col>)
        }
      </Row>
    </>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <div align="center" style={{ margin: 50 }}>
            Erro ao buscar informações da página
          </div>
        }
      </div>
    );
  }
}

export default MuraisFotosAluno;
