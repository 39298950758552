import React from 'react';
import { withRouter } from 'hoc/withRouter';

import { Link } from 'react-router-dom';

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Row
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import ConfirmModal from 'views/components/ConfirmModal';
import AdaptiveTable from 'views/components/AdaptiveTable';

import AnosLetivosService from 'services/AnosLetivosService';
import DiariosService from 'services/DiariosService';
import EntesService from 'services/EntesService';
import EscolasService from 'services/EscolasService';
import LoginService from 'services/LoginService';
import ProfessorAuxiliarService from 'services/ProfessorAuxiliarService';
import TurmasService from 'services/TurmasService';
import UsuarioService from 'services/UsuarioService';
import RegistroDiarioAlunoService from 'services/RegistroDiarioAlunoService';

import Papel from 'assets/csv/papeis.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';
import Turno from 'assets/csv/turnos.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';

class DiarioAluno extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.state = {
      showAlert: false,
      showLoaderModal: false,
      buttonDisabled: false,

      error: null,

      dadosTabela: {
        header: [['Dia', '90%']],
        columnAlign: ['text-left'],
        keys: ['data'],
        rows: [],
        total: undefined,
      },
    };

    this.loginService = new LoginService();
    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.usuarioService = new UsuarioService();
    this.professorAuxiliarService = new ProfessorAuxiliarService();
    this.registroDiarioAlunoService = new RegistroDiarioAlunoService();
  }

  async componentDidMount() {
    this.idPagina = `${this.props.dadosUsuario.idVinculo}-diarioaluno-${this.props.match.params.idAluno}`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    await this.loginService.verificarPapel(this.props.role);
    try {
      const dadosEnte = await this.entesService
        .carregarDadosEnte();

      const professoreAuxiliar = await this.professorAuxiliarService
        .carregarMeuProfessorAuxiliar();

      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const matricula = await this.turmasService
        .carregarMatricula(this.props.match.params.idTurma, this.props.match.params.idMatricula);

      let matriculas = [];
      if (!turma.multiSeriado) {
        matriculas = await this.diariosService
          .carregarMatriculas(this.props.match.params.idDiario);
      } else {
        const data = await this.turmasService
          .carregarMatriculasTurmaMultiseriado(turma.turmaMultiseriado.id, 1, 999999, '');
        matriculas = data.matriculas.filter((matricula) => matricula.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value);
      }

      
      matriculas.forEach((matricula) => {
        Object.assign(matricula, {
          label: matricula.usuario.nome,
          value: matricula.id,
        })
      });

      const matriculaSelecionada = {
        ...matricula,
        label: matricula.usuario.nome,
        value: matricula.id,
      };

      await this.carregarRegistrosDiarioAluno(this.paginaSelecionada, this.linhasPorPagina);

      this.setState({
        dadosEnte,
        anoLetivo,
        escola,
        turma,
        diario,
        matricula,
        matriculaSelecionada,
        selectMatriculas: matriculas,
        auxiliarBloqueado: this.props.role === Papel.PROFESSOR.value && professoreAuxiliar.auxiliar && dadosEnte.bloquearAlteracaoDiarioProfessorAuxiliar,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarRegistrosDiarioAluno(pagina, linhasPorPagina) {
    try {
      const data = await this.registroDiarioAlunoService
        .carregarRegistrosDiarioAluno(this.props.match.params.idDiario, this.props.match.params.idMatricula, pagina, linhasPorPagina);

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, {
          rows: data.registrosDiarioAluno,
          total: data.total,
        }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar registros do diário do aluno',
      });
    }
  }

  cadastrarRegistroDiarioAluno() {
    this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao/registrosdiario`
      + `/matricula/${this.props.match.params.idMatricula}/cadastro`);
  }

  editarRegistroDiarioAluno(registroDiarioAluno) {
    this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao/registrosdiario`
      + `/matricula/${this.props.match.params.idMatricula}/edicao/${registroDiarioAluno.id}`);
  }

  registroDiarioAlunoSelecionado(registroDiarioAluno) {
    this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao`
      + `/registrosdiario/${registroDiarioAluno.id}`);
  }

  confirmarRemocaoRegistroDiarioAluno(registroDiarioAluno) {
    this.setState({
      showConfirmModal: true,
      registroDiarioAlunoSelecionado: registroDiarioAluno
    })
  }

  async removerRegistroDiarioAluno() {
    this.setState({ showAlert: false, showLoaderModal: true });

    try {
      await this.registroDiarioAlunoService
        .removerRegistroDiarioAluno(this.state.registroDiarioAlunoSelecionado.id);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Registro de diário do aluno removido com sucesso',
      });

      await this.carregarRegistrosDiarioAluno(this.paginaSelecionada, this.linhasPorPagina);
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover escola',
      });
    }
  }

  voltar() {
    if (this.state.turma.multiSeriado) {
      return `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}/diario`;
    }
    if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
      return `${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`
        + `/diario/${this.state.diario.id}`;
    }
    return `${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}`
      + `/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao`;
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            {
              !this.state.turma.multiSeriado
                ? <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
                : <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            }
            {
              !this.state.turma.multiSeriado
                ? <BreadcrumbItem><Link to={this.voltar()}>{this.state.diario.nome}</Link></BreadcrumbItem>
                : <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}/diario`}>Diário único</Link></BreadcrumbItem>
            }
            <BreadcrumbItem active>{this.state.matricula.usuario.nome}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Diário do aluno
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup className={'has-label has-danger'}>
                    <label>Aluno</label>
                    <Select
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={`react-select primary ${this.state.matriculaSelecionadaState}`}
                      classNamePrefix="react-select"
                      value={this.state.matriculaSelecionada}
                      onChange={async (event) => {
                        this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
                          + `/escola/${this.props.match.params.idEscola}/turma/${this.props.match.params.idTurma}`
                          + `/diario/${this.props.match.params.idDiario}/alfabetizacao`
                          + `/registrosdiario/matricula/${event.id}`);

                        this.setState({ loadingDiarioAluno: true });
                        await this.componentDidMount();
                        this.setState({ loadingDiarioAluno: false });
                      }}
                      options={this.state.selectMatriculas}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {

                this.state.loadingDiarioAluno ? <div align="center" style={{ padding: 30 }}>
                  <Loader
                    type="Oval"
                    color="#34B5B8"
                    height="50"
                    width="50" />
                </div> : <Row className="mt-4">
                  <Col>
                    <AdaptiveTable
                      disableSearchField
                      selectedPage={this.paginaSelecionada}
                      rowsPerPage={this.linhasPorPagina}
                      disablePrintAction
                      clickRows
                      editButtonCallback={(registroDiarioAluno) => this.editarRegistroDiarioAluno(registroDiarioAluno)}
                      removeButtonCallback={(registroDiarioAluno) => this.confirmarRemocaoRegistroDiarioAluno(registroDiarioAluno)}
                      rowsPerPageCallback={(info) => {
                        this.paginaSelecionada = 1;
                        sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                        this.linhasPorPagina = info.rowsPerPage;
                        sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                        this.carregarRegistrosDiarioAluno(this.paginaSelecionada, info.rowsPerPage);
                      }}
                      paginatorCallback={(page) => {
                        this.paginaSelecionada = page;
                        sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                        this.carregarRegistrosDiarioAluno(this.paginaSelecionada, this.linhasPorPagina);
                      }}
                      rowCallback={(registroDiarioAluno) => this.registroDiarioAlunoSelecionado(registroDiarioAluno)}
                      data={this.state.dadosTabela} />
                  </Col>
                </Row>
              }
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history
                      .push(this.voltar())} >
                    Voltar
                  </Button>
                </Col>
                <Col className="text-right">
                  {
                    this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado && <Button
                      color="primary"
                      disabled={this.state.buttonDisabled}
                      onClick={() => this.cadastrarRegistroDiarioAluno()} >
                      Cadastrar diário
                    </Button>
                  }
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerRegistroDiarioAluno();
            } else {
              this.setState({ registroDiarioAlunoSelecionado: null });
            }
          }}
          text='Confirme a exclusão do registro de diário do aluno' />
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text="Salvando notas..." />
      </div>
    );
  }
}

export default withRouter(DiarioAluno);